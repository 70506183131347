/**
 * Creates a list of numbers from 1 to the specified integer.
 * @param n The integer specifying the upper limit of the number list.
 * @returns An array of numbers from 1 to `n`.
 */
export default function convertNumToList(n: number): number[] {
    const numberList: number[] = [];
  
    // Iterate from 1 to `n` and add each number to the `numberList` array
    for (let i = 1; i <= n; i++) {
      numberList.push(i);
    }
  
    return numberList;
  }