import React from 'react';
import { useTranslation } from 'react-i18next';

interface StepIndicatorProps {
  step: 1 | 2 | 3 | 4;
  pickupAddress: string;
  destinationAddress: string;
  time: string;
  returnDateTime: string;
  activeColor: string;
  is_round:boolean
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ step, pickupAddress, destinationAddress, time, activeColor ,is_round,returnDateTime}) => {
  const getStatusClass = (currentStep: number, stepNumber: number) => {
    if (currentStep === stepNumber) return `${activeColor} text-blue-600`;
    if (stepNumber === 1 && pickupAddress) return `text-[#00939C]`;
    if (stepNumber === 2 && destinationAddress) return `text-[#00939C]`;
    return 'text-gray-500';
  };
	const { t } = useTranslation();

  return (
    <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse mb-4">
      <li className={`flex items-center space-x-2.5 rtl:space-x-reverse ${getStatusClass(step, 1)}`}>
        <span className={`flex items-center justify-center w-8 h-8 border ${step === 1 ? activeColor : 'border-gray-500'} rounded-full shrink-0`}>
          1
        </span>
        <span>
          <h3 className={`font-medium leading-tight ${step === 1 ? `text-${activeColor}` : ''}`}>{t('Pickup Address')}</h3>
          <p className="text-sm">{pickupAddress ? pickupAddress : t('Select_your_pickup_address')}</p>
        </span>
      </li>
      <li className={`flex items-center space-x-2.5 rtl:space-x-reverse ${getStatusClass(step, 2)}`}>
        <span className={`flex items-center justify-center w-8 h-8 border ${step === 2 ? activeColor : 'border-gray-500'} rounded-full shrink-0`}>
          2
        </span>
        <span>
          <h3 className={`font-medium leading-tight ${step === 2 ? `text-${activeColor}` : ''}`}>{t('Destination Address')}</h3>
          <p className="text-sm">{destinationAddress ? destinationAddress : t('Select_your_destination_address')}</p>
        </span>
      </li>
      <li className={`flex items-center space-x-2.5 rtl:space-x-reverse ${getStatusClass(step, 3)}`}>
        <span className={`flex items-center justify-center w-8 h-8 border ${step === 3 ? activeColor : 'border-gray-500'} rounded-full shrink-0`}>
          3
        </span>
        <span>
          <h3 className={`font-medium leading-tight ${step === 3 ? `text-${activeColor}` : ''}`}>{t('Time')}</h3>
          <p className="text-sm">{time ? time : t('Select the time for your trip')}</p>
        </span>
      </li>
      {is_round && 
      <li className={`flex items-center space-x-2.5 rtl:space-x-reverse ${getStatusClass(step, 4)}`}>
        <span className={`flex items-center justify-center w-8 h-8 border ${step === 3 ? activeColor : 'border-gray-500'} rounded-full shrink-0`}>
          4
        </span>
        <span>
          <h3 className={`font-medium leading-tight ${step === 3 ? `text-${activeColor}` : ''}`}>date time return trip</h3>
          <p className="text-sm">{returnDateTime ? returnDateTime : 'Select the date time return for your trip'}</p>
        </span>
      </li>
      }
    </ol>
  );
};

export default StepIndicator;
