import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation } from "react-router-dom";

export interface MenuBarProps {
	className?: string;
	iconClassName?: string;
}
const MenuBar: React.FC<MenuBarProps> = ({
	className = "p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300",
	iconClassName = "h-7 w-7",
}) => {
	const [isVisable, setIsVisable] = useState(false);

	const location = useLocation();

	useEffect(() => {
		setIsVisable(false);
	}, [location]);

	const handleOpenMenu = () => setIsVisable(true);
	const handleCloseMenu = () => setIsVisable(false);

	const renderContent = () => {
		return (
			<Transition appear show={isVisable} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50 overflow-hidden "
					onClose={handleCloseMenu}
				>
					<Transition.Child
						as={Fragment}
						enter=" duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave=" duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
					</Transition.Child>
					<div className="fixed inset-0">
						<div className="flex min-h-full justify-end ">
							<Transition.Child
								as={Fragment}
								enter="transition duration-100 transform"
								enterFrom="opacity-0 translate-x-56"
								enterTo="opacity-100 translate-x-0"
								leave="transition duration-150 transform"
								leaveFrom="opacity-100 translate-x-0"
								leaveTo="opacity-0 translate-x-56"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden transition-all ">
									<NavMobile onClickClose={handleCloseMenu} />
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		);
	};

	return (
		<>
			<button
				onClick={handleOpenMenu}
				className={`flex items-center mt-2 sm:hidden justify-center focus:outline-none ${className}`}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className={iconClassName}
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
						clipRule="evenodd"
					/>
				</svg>
			</button>

			{renderContent()}
		</>
	);
};

export default MenuBar;
