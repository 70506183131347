import React, { FC, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import markerIconUrl from './favicon.svg'; // Icon for saved addresses
import previewIconUrl from './favicon.svg'; // Preview icon for address markers

interface Address {
  id: number;
  name: string;
  map_location: {
    lat: number;
    lng: number;
    address_name: string;
  };
}

interface Props {
  show: boolean;
  onClose: () => void;
  pickupAddress: Address;
  destinationAddress: Address;
}

// Function to decode polyline points
const decodePolyline = (encoded: string): L.LatLng[] => {
  let points: L.LatLng[] = [];
  let index = 0, len = encoded.length;
  let lat = 0, lng = 0;

  while (index < len) {
    let b, shift = 0, result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
    lng += dlng;

    points.push(new L.LatLng(lat / 1e5, lng / 1e5));
  }

  return points;
};

const DirectionPreviewContainer: FC<Props> = ({ show, onClose, pickupAddress, destinationAddress }) => {
  const [route, setRoute] = useState<L.LatLng[]>([]);
  const [distance, setDistance] = useState<string>('');

  useEffect(() => {
    if (show) {
      const fetchDirections = async () => {
        try {
          const response = await axios.get('https://maps.googleapis.com/maps/api/directions/json', {
            params: {
              origin: `${pickupAddress.map_location.lat},${pickupAddress.map_location.lng}`,
              destination: `${destinationAddress.map_location.lat},${destinationAddress.map_location.lng}`,
              key: 'AIzaSyAFHCKWvkcHl7nu-LafKC3DvGBZq6p9HxE',
            },
          });
          const { routes } = response.data;
          if (routes.length > 0) {
            const { legs, overview_polyline } = routes[0];
            if (legs.length > 0) {
              const { distance } = legs[0];
              setDistance(distance.text);
              const latLngs = decodePolyline(overview_polyline.points);
              setRoute(latLngs);
            }
          }
        } catch (error) {
          console.error('Error fetching directions:', error);
        }
      };

      fetchDirections();
    }
  }, [show, pickupAddress, destinationAddress]);

  const markerIcon = L.icon({
    iconUrl: markerIconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const previewIcon = L.icon({
    iconUrl: previewIconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  if (!show) return null;

  return (
    <div className="direction-preview-container" style={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', marginTop: '20px' }}>
      <div>
        <h2>Direction Preview</h2>
        <button onClick={onClose} style={{ float: 'right' }}>Close</button>
      </div>
      <MapContainer center={[pickupAddress.map_location.lat, pickupAddress.map_location.lng]} zoom={13} style={{ height: '400px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[pickupAddress.map_location.lat, pickupAddress.map_location.lng]} icon={markerIcon}>
          <Popup>{pickupAddress.name}</Popup>
        </Marker>
        <Marker position={[destinationAddress.map_location.lat, destinationAddress.map_location.lng]} icon={previewIcon}>
          <Popup>{destinationAddress.name}</Popup>
        </Marker>
        <Polyline positions={route} color="blue" />
      </MapContainer>
      <div className="mt-3">
        <p>Distance: {distance}</p>
      </div>
    </div>
  );
};

export default DirectionPreviewContainer;
