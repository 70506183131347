import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "@material-tailwind/react";
import classes from "./PasswordCard.module.css";
import OtpInput from "react-otp-input";
import ProfileButtom from "components/ProfileButtom/ProfileButtom";
import { useChangePass } from "hooks/DataSend/useChangePass";

const PasswordCard = () => {
	const [curr, setCurr] = useState("");
	const [password, setPassword] = useState("");
	const [resetPassword, setResetPassword] = useState("");
	const { mutate } = useChangePass();
	const phone = localStorage.getItem("phone");
	const navigate = useNavigate();

	useEffect(() => {
		if (!phone) {
			navigate("/login"); // Redirect to the login page if there is no phone value
		}
	}, [phone, navigate]);

	const changeHandler = () => {
		const data = new FormData();
		data.append("phonecode", "20");
		// data.append("code", "4391");
		data.append("mobile", phone || "");
		data.append("current_password", curr || "");
		data.append("new_password", password);
		data.append("new_password_confirmation", resetPassword);

		mutate(data);
	};

	const handleInputChange = (
		e: any,
		setState: React.Dispatch<React.SetStateAction<any>>,
	) => {
		const event = {
			target: {
				value: e,
			},
		};
		setState(event);
	};

	return (
		<div className={classes.passwordCard}>
			<h2>Change password</h2>
			<div className={classes.passInput}>
				<label>Current password *</label>
				<Input
					className="[h-78px]  border-1 mt-2 w-full rounded-[4px] border p-3  text-[#69696A]"
					type="password"
					onChange={e => setCurr(e.target.value)}
				/>
			</div>
			<div className={classes.passInput}>
				<label>New password *</label>
				<Input
					className="[h-78px]  border-1 mt-2 w-full rounded-[4px] border p-3  text-[#69696A]"
					type="password"
					onChange={e => setPassword(e.target.value)}
				/>
			</div>
			<div className={classes.passInput}>
				<label>Confirm new password *</label>
				<Input
					className="[h-78px]  border-1 mt-2 w-full rounded-[4px] border p-3  text-[#69696A]"
					type="password"
					onChange={e => setResetPassword(e.target.value)}
				/>
			</div>
			<ProfileButtom title="Done" mt={false} onClick={changeHandler} />
		</div>
	);
};

export default PasswordCard;
