import { TravelData } from "./types";

export default function filtered_data_to_set_data(
    list_travel_Data: TravelData[],
    listArrivalTimes: string[],
    listDepartureTimes: string[],
    listPrices: string[],
    listCompanies: string[],
    listClasses: string[],
    listdepartures: string[],
    listArrival: string[]
  ): TravelData[] {
    try {
      // Create an empty array to store the filtered data
      const result: TravelData[] = [];
  
      // Use map to loop through the travel data
      list_travel_Data.map((data) => {
        const arrivalTime = data.arrival_at;
        const departureTime = data.travel_at;
        const price = data.prices_start_with.toString();
        const company = data.company_data.name;
        const class_trip = data.classes;
        const departureStation = data.travel_from;
        const arrivalStation = data.travel_to;
      
        // Check each condition using if statements
        if (
          (listArrivalTimes.length === 0 || listArrivalTimes.includes(arrivalTime)) &&
          (listDepartureTimes.length === 0 || listDepartureTimes.includes(departureTime)) &&
          (listPrices.length === 0 || listPrices.includes(price)) &&
          (listCompanies.length === 0 || listCompanies.includes(company)) &&
          (listClasses.length === 0 || listClasses.includes(class_trip)) &&
          (listdepartures.length === 0 || listdepartures.includes(departureStation)) &&
          (listArrival.length === 0 || listArrival.includes(arrivalStation)) 


        ) {
          // If all conditions are true, push the data to the result array
          result.push(data);
        }
      });
  
      // Return the filtered data
      return result;
    } catch (error) {
      // Return an empty array if an error occurs
      return [];
    }
  }