import React, { useEffect, useState } from "react";
import { QueryClient } from "react-query";
import { QueryClientProvider } from "react-query";
import MyRouter from "routers/index";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ChangeLanguageDocumentAttributes } from "utils";
import { AppContext } from "components/context/AppContext";
import { useTranslation } from "react-i18next";
import loadCairoFont from './utils/fontsLoader';
import axios from "axios";
import i18n from "i18n";

function App() {
	//test
	// ammar test
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");
	const { i18n } = useTranslation();
	useEffect(() => {
		const lName: any = localStorage.getItem("name");
		const lPhone: any = localStorage.getItem("phone");
		const lemail: any = localStorage.getItem("email");
		const ltoken: any = localStorage.getItem("token");
		setName(lName);
		setPhone(lPhone);
		setEmail(lemail);
		setToken(ltoken);
	}, []);
	const queryClient = new QueryClient();
	useEffect(() => {
		ChangeLanguageDocumentAttributes();
		const formSubmitHandler = () => {
			
	
			const data = new FormData();
			data.append("mobile", '1000000000');
			data.append("phonecode", "20");
			data.append("password", '1000000000');
		addLogin(data)
			localStorage.setItem("phone", `${phone}`);
		};
// formSubmitHandler()
	}, []);
	return (
		<QueryClientProvider client={queryClient}>


			<AppContext.Provider value={{ name, phone, email, token }}>
				<div className="h-full w-full bg-[#E9EBF8]  text-base text-neutral-900 ltr:font-alkatra rtl:font-messiri dark:bg-neutral-900 dark:text-neutral-200">
					<MyRouter />
					
				</div>
				<ToastContainer className="toast" />
				
			</AppContext.Provider>
		</QueryClientProvider>
	);
}

export default App;
loadCairoFont();

async function addLogin(data:any) {
	try {
	  const response = await axios.post(
		`${process.env.REACT_APP_API_TELE_URL}/api/v1/mobile/customer/login`,
		data,
		{
		  headers: {
			"Content-Type": "multipart/form-data",
			"Accept-Language": i18n?.language,
		  },
		}
	  );
  
	  // Assuming the response contains the necessary data
	  const responseData = response.data;
  
	  await localStorage.setItem("token", responseData?.data?.api_token);
	  await localStorage.setItem("email", responseData?.data?.email);
	  await localStorage.setItem("phone", responseData?.data?.mobile);
	  await localStorage.setItem("name", responseData?.data?.name);
  
	  // Return the response or any other relevant data
	  return response;
	} catch (error) {
	  // Handle any errors that occurred during the request
	  console.error("Login error:", error);
	  throw error;
	}
  }
