import React, { FC, useCallback, useContext, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import useInput from "hooks/useInput";
import { Value } from "sass";
import { useAddLogin } from "hooks/DataSend/useLogin";
import { AppContext } from "components/context/AppContext";
import OtpInput from "react-otp-input";
import {
	LoginSocialGoogle,
	LoginSocialFacebook,
	IResolveParams,
} from "reactjs-social-login";
import i18n from "i18n";
export interface PageLoginProps {
	className?: string;
}
const REDIRECT_URI = "https://www.telefreik.com/login";

const loginSocials = [
	{
		name: "continueWithFacebook",
		href: "#",
		icon: facebookSvg,
	},
	{
		name: "continueWithGoogle",
		href: "#",
		icon: googleSvg,
	},
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const [provider, setProvider] = useState("");
	const [profile, setProfile] = useState<any>();
	const {
		value: password,
		isValid: passwordISValid,
		hasError: passwordHasError,
		inputBlurHandler: passwordBlur,
		valueChangeHandler: passwordChange,
	} = useInput((value: any) => value.length === 6);
	const {
		value: phone,
		isValid: PhoneISValid,
		hasError: phoneHasError,
		inputBlurHandler: phoneBlur,
		valueChangeHandler: phoneChange,
		reset: resetPhone,
	} = useInput((value: any) => value.length <= 12);

	const { mutate } = useAddLogin();

	const { token } = useContext(AppContext);
	const formSubmitHandler = (event: any) => {
		event.preventDefault();
		if (!PhoneISValid) {
			return;
		}
		const officialPhone = phone?.substring(1);

		const data = new FormData();
		data.append("mobile", officialPhone);
		data.append("phonecode", "20");
		data.append("password", password);

		mutate(data);
		localStorage.setItem("phone", `${phone}`);
	};
	const onLoginStart = useCallback(() => {
		alert("login start");
	}, []);
	const onLogoutSuccess = useCallback(() => {
		setProfile(null);
		setProvider("");
		alert("logout success");
	}, []);
	const currentLanguage: string = i18n.language;

	return (
		<div
			className={`nc-PageLogin ${className} relative mb-[20%] h-[80vh] bg-gradient-to-r from-[#2E74BD] to-[#2B356E]  max-sm:mb-[35%]`}
			data-nc-id="PageLogin "
		>
			{currentLanguage === "ar" ? (
				<title>احجز أفضل عروض الطیران والمواصلات في مصر - سفریة</title>
			) : (
				<title>TBook flight and transportation deals in Egypt - Safaria</title>
			)}
			<div
				className="container absolute top-20  left-2 right-2 w-[80vw] rounded-[16px] bg-white transition-[0.5s] lg:mb-32"
				style={{
					boxShadow: " 0px 4px 4px 0px rgba(217, 217, 217, 0.25)",
				}}
			>
				{/* taps container */}
				<div className="mt-[42px] flex justify-center ">
					<div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px] bg-[#0074C3] text-center text-white ">
						{t("login")}
					</div>
					<div className="flex h-[50px] w-[170px] items-center justify-center rounded-[24px]  text-center ">
						{" "}
						{!token && (
							<span className="block text-center  dark:text-neutral-300 ">
								{/* {t("newUser")} */}
								{` `}
								<Link to="/signup">
									<span className="">
										{/* {t("createAnAccount")} */}
										{t("Sign up")}
									</span>
								</Link>
							</span>
						)}
					</div>
				</div>
				<div className="mx-auto max-w-md space-y-14 ">
					<div className="grid "></div>
					{/* FORM */}
					<form
						className="grid grid-cols-1 gap-4 "
						action="#"
						method="post"
						onSubmit={formSubmitHandler}
					>
						<label className="block">
							<span className="text-[#69696A] dark:text-neutral-200">
								{t("phoneNumber")}*
							</span>
							<input
								type="tel"
								pattern="[0-9]{10,12}"
								placeholder="0105478...."
								className="h-78px text-69696A mt-2 rounded-md p-3"
								value={phone}
								onChange={phoneChange}
								onBlur={phoneBlur}
								autoFocus
							/>
							{phoneHasError && (
								<p className="mt-1 text-red-900">
									{t("Enter a valid phone number")}
								</p>
							)}
						</label>
						<label className="block">
							<span className="text-[#69696A] dark:text-neutral-200 ">
								{t("password")}
							</span>
							<input
								// value={password}
								className="[h-78px]  border-1 mt-2 w-full rounded-[4px] border p-3  text-[#69696A]"
								type="password"
								onChange={(e: any) => {
									const event: any = {
										target: {
											value: e,
										},
									};
								passwordChange(event.target.value);
								}}
								placeholder="inter passwords"
							/>
							{passwordHasError && (
								<p className="mt-1 text-red-900">{t("inValidPassword")}</p>
							)}
						</label>
						<button
							type="submit"
							className=" btn-hover h-[50px] rounded-[20px] text-white"
						>
							{t("continue")}
						</button>
						<label className="flex w-100  justify-content-center ">
							<Link to="/forgot-pass" className="mx-auto">
								<span className="text-blue-700 text-center dark:text-blue-700 ">
									{t("forgotPassword")}{" "}?
								</span>
							</Link>
						</label>
					</form>
					{/* ==== */}
					{/* OR */}
					<div className="relative text-center">
						{/* <span className="relative z-10 inline-block bg-white px-4 text-sm font-medium dark:bg-neutral-900 dark:text-neutral-400">
							{t("or")}
						</span> */}
						<div className="absolute left-0 top-1/2 w-full -translate-y-1/2 transform border border-neutral-100 dark:border-neutral-800"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageLogin;
