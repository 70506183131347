import React, { useEffect, useState } from "react";
import { TravelData } from "../../../utils/types";
import exportTravelAtAsDateTime from "../../../utils/get_travel_at_arr";
import { useTranslation } from "react-i18next";
import SliderFiltersInRange from "./SlideFilterRange";
import sortByTravelAt from "utils/sortTravelDataByTravelAt";
import sortByArrivalAt from "utils/sortTravleDataByArrivalAt";
import sortByPrice from "utils/sortedTravelDataByPrices";
import Select from "shared/Select/Select";
import removeDuplicatesInArr from "utils/removeDuplicatesInArr";
import Input from "shared/Input/Input";
import convertStringToArray from "utils/convertStringToArr";
import filtered_data_to_set_data from "utils/filterTravleDataInManyAttr";
import ChekBoxFilterComponent from "./CheckBoxFilter";
import CheckBoxComponentFilter from "./CheckBoxContainerFilter";

interface FiltersProps {
	refactoredData: TravelData[];
	displayedData: TravelData[];
	is_mobile: boolean;
	setData: (data: TravelData[]) => void;
}

const Filters: React.FC<FiltersProps> = ({
	refactoredData,
	displayedData,
	setData,
	is_mobile,
}) => {
	const { t } = useTranslation();
	const [isTravelingOpen, setIsTravelingOpen] = useState(!is_mobile);
	const [isPriceOpen, setIsPriceOpen] = useState(!is_mobile);
	const [companyNames, setCompanyNames] = useState<string[]>([]);
	const [classesNames, setClassesNames] = useState<string[]>([]);
	const [departuresNames, setDeparturesNames] = useState<string[]>([]);
	const [arrivalNames, setArrivalsNames] = useState<string[]>([]);
	const [companyNamesDisplayed, setCompanyNamesDisplayed] = useState<string[]>(
		exportTravelAtAsDateTime(refactoredData, "company_name"),
	);
	const [departureStationDisplayed, setDepatureStationDisplayed] = useState<
		string[]
	>(exportTravelAtAsDateTime(refactoredData, "departureStations"));
	const [arrivalStationDisplayed, setarrivalsStationDisplayed] = useState<
		string[]
	>(exportTravelAtAsDateTime(refactoredData, "arrivalStations"));
	const [classesDisplayed, setClassesDisplayed] = useState<string[]>(
		exportTravelAtAsDateTime(refactoredData, "classes_trip"),
	);
	const toggleTravelingOpen = () => {
		setIsTravelingOpen(!isTravelingOpen);
	};

	const togglePriceOpen = () => {
		setIsPriceOpen(!isPriceOpen);
	};

	const travelAtDateTimeArray: string[] = exportTravelAtAsDateTime(
		sortByTravelAt(refactoredData),
		"traveling",
	);
	const arrivalAtDateTimeArray: string[] = exportTravelAtAsDateTime(
		sortByArrivalAt(refactoredData),
		"arrival",
	);
	const PriceArray: string[] = exportTravelAtAsDateTime(
		sortByPrice(refactoredData),
		"price",
	);
	const is_opened_icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			aria-hidden="true"
			className="ml-2
 h-4 w-4 -rotate-180  transition duration-150 ease-in-out group-hover:text-opacity-80"
		>
			<path
				fillRule="evenodd"
				d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
				clipRule="evenodd"
			></path>
		</svg>
	);
	const is_closed_icon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			aria-hidden="true"
			className="ml-2
 h-4 w-4 text-opacity-70  transition duration-150 ease-in-out group-hover:text-opacity-80"
		>
			<path
				fillRule="evenodd"
				d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
				clipRule="evenodd"
			></path>
		</svg>
	);

	useEffect(() => {
		let storedArrivalTimes = convertStringToArray(
			`${localStorage.getItem("listArrivalTimes")}`,
		);
		let storedDepartureTimes = convertStringToArray(
			`${localStorage.getItem("listDepartureTimes")}`,
		);
		let storedPrice = convertStringToArray(
			`${localStorage.getItem("listPrices")}`,
		);
		setData(
			filtered_data_to_set_data(
				refactoredData,
				storedArrivalTimes,
				storedDepartureTimes,
				storedPrice,
				companyNames,
				classesNames,
				departuresNames,
				arrivalNames,
			),
		);
	}, [companyNames, classesNames, departuresNames, arrivalNames]);
	function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
		const companyName = e.target.value;
		const isChecked = e.target.checked;

		setCompanyNames(prevCompanyNames => {
			if (isChecked) {
				return [...prevCompanyNames, companyName];
			} else {
				return prevCompanyNames.filter(name => name !== companyName);
			}
		});
	}
	function handleCheckboxChange_classes(
		e: React.ChangeEvent<HTMLInputElement>,
	): void {
		const className = e.target.value;
		const isChecked = e.target.checked;

		setClassesNames(prevCompanyNames => {
			if (isChecked) {
				return [...prevCompanyNames, className];
			} else {
				return prevCompanyNames.filter(name => name !== className);
			}
		});
	}
	function handleCheckboxChange_DepartureStations(
		e: React.ChangeEvent<HTMLInputElement>,
	): void {
		const departure = e.target.value;
		const isChecked = e.target.checked;

		setDeparturesNames(prevDenatureName => {
			if (isChecked) {
				return [...prevDenatureName, departure];
			} else {
				return prevDenatureName.filter(name => name !== departure);
			}
		});
	}
	function handleCheckboxChange_arrivalStations(
		e: React.ChangeEvent<HTMLInputElement>,
	): void {
		const arrival = e.target.value;
		const isChecked = e.target.checked;

		setArrivalsNames(prevDenatureName => {
			if (isChecked) {
				return [...prevDenatureName, arrival];
			} else {
				return prevDenatureName.filter(name => name !== arrival);
			}
		});
	}

	return (
		<div
			className={`w-full lg:flex lg:flex-col lg:gap-6 ${
				is_mobile &&
				" flex flex-row justify-evenly overflow-auto  align-middle "
			}`}
		>
			<div
				className={`filter-section  rounded-lg bg-white p-[0.625rem] max-sm:ltr:ml-[1rem] max-sm:rtl:mr-[1.5rem]   `}
			>
				<div
					className={`filter-header flex cursor-pointer items-center justify-between  py-2 px-4 ${
						is_mobile &&
						"rounded-lg border-[0.1rem]  border-gray-300 rtl:justify-between rtl:gap-3 rtl:p-2 "
					} `}
					onClick={toggleTravelingOpen}
				>
					<span className="filter-title w-[78px] text-[1.05rem] text-[#1E1E1E] first-letter:uppercase max-sm:rtl:w-[118px]">
						{t("Bus times")}
					</span>
					<span className="filter-icon h-fit w-fit  text-[#DDE2EB]">
						{!isTravelingOpen ? is_closed_icon : is_opened_icon}
					</span>
				</div>
				{isTravelingOpen && (
					<div
						className={` ${
							is_mobile &&
							"absolute left-2 right-2 mt-10 flex flex-col gap-8 rounded bg-white  pb-8 pt-2 shadow-xl max-sm:w-[100%] sm:w-[40%] "
						} flex flex-col gap-6`}
					>
						<SliderFiltersInRange
							RefactoredData={refactoredData}
							setData={setData}
							displayedData={displayedData}
							times={travelAtDateTimeArray}
							type="traveling"
							companyNamesList={companyNames}
							classes_trip={classesNames}
							slider_stations={departuresNames}
							arrival_stations={arrivalNames}
						/>
						<SliderFiltersInRange
							RefactoredData={refactoredData}
							setData={setData}
							displayedData={displayedData}
							times={arrivalAtDateTimeArray}
							type="arrival"
							companyNamesList={companyNames}
							classes_trip={classesNames}
							slider_stations={departuresNames}
							arrival_stations={arrivalNames}
						/>
					</div>
				)}
			</div>

			<div className="filter-section  rounded-lg bg-white p-[0.625rem]">
				<div
					className={`filter-header flex cursor-pointer items-center justify-between  py-2 px-4 ${
						is_mobile &&
						"rounded-lg border-[0.1rem]  border-gray-300 rtl:justify-between rtl:gap-3 rtl:p-2"
					} `}
					onClick={togglePriceOpen}
				>
					<span className="filter-title text-[1.05rem] text-[#1E1E1E] first-letter:uppercase">
						{t("Price")}
					</span>
					<span className="filter-icon h-fit w-fit  text-[#DDE2EB]">
						{!isPriceOpen ? is_closed_icon : is_opened_icon}
					</span>
				</div>
				{isPriceOpen && (
					<div
						className={` ${
							is_mobile &&
							"absolute left-2 right-2 mt-10 flex flex-col gap-8 rounded bg-white  pb-8 pt-2 shadow-xl max-sm:w-[100%] sm:w-[40%] "
						}`}
					>
						<SliderFiltersInRange
							RefactoredData={refactoredData}
							setData={setData}
							displayedData={displayedData}
							times={PriceArray}
							type="price"
							companyNamesList={companyNames}
							classes_trip={classesNames}
							slider_stations={departuresNames}
							arrival_stations={arrivalNames}
						/>
					</div>
				)}
			</div>

			<CheckBoxComponentFilter
				filterTitle={t("Operators")}
				is_mobile={is_mobile}
				filterType={"sm"}
				isClosedIcon={is_closed_icon}
				isOpenedIcon={is_opened_icon}
				companyNamesDisplayed={companyNamesDisplayed}
				companyNames={companyNames}
				handleCheckboxChange={e => handleCheckboxChange(e)}
			/>
			<CheckBoxComponentFilter
				filterTitle={t("Classes")}
				is_mobile={is_mobile}
				isClosedIcon={is_closed_icon}
				filterType={"sm"}
				isOpenedIcon={is_opened_icon}
				companyNamesDisplayed={classesDisplayed}
				companyNames={classesNames}
				handleCheckboxChange={e => handleCheckboxChange_classes(e)}
			/>
			<CheckBoxComponentFilter
				filterTitle={t("departure Stations")}
				is_mobile={is_mobile}
				filterType={"big"}
				isClosedIcon={is_closed_icon}
				isOpenedIcon={is_opened_icon}
				companyNamesDisplayed={departureStationDisplayed}
				companyNames={departuresNames}
				handleCheckboxChange={e => handleCheckboxChange_DepartureStations(e)}
			/>
			<CheckBoxComponentFilter
				filterTitle={t("Arrival Stations")}
				filterType={"big"}
				is_mobile={is_mobile}
				isClosedIcon={is_closed_icon}
				isOpenedIcon={is_opened_icon}
				companyNamesDisplayed={arrivalStationDisplayed}
				companyNames={arrivalNames}
				handleCheckboxChange={e => handleCheckboxChange_arrivalStations(e)}
			/>
		</div>
	);
};

export default Filters;
