import React, { FC, useLayoutEffect } from "react";
import homeBg1 from "images/homeBg1.jpg";
import homeBg2 from "images/homeBg2.jpg";
import homeBg3 from "images/Bus_Safaria_Png.png";
import homeBg4 from "images/homeBg4.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
	className?: string;

}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
	const { t } = useTranslation();
	const slides = [
		{
			url: homeBg3,
			progress: "25%",
		},
		{
			url: homeBg2,
			progress: "50%",
		},
		{
			url: homeBg1,
			progress: "75%",
		},

		{
			url: homeBg4,
			progress: "100%",
		},
	];

	const [currentIndex, setCurrentIndex] = React.useState(0);
	const [openTab, setOpenTab] = React.useState("");
	useLayoutEffect(() => {
		window.addEventListener("storage", () => {
			const sessionTab = sessionStorage.getItem("currentActiveTab");
			if (sessionTab) {
				setOpenTab(sessionTab);
			}
			// ...
		});
		switch (openTab) {
			case "Flights":
				setCurrentIndex(2);
				break;
			case "Bus":
				setCurrentIndex(0);

				break;
			case "Cars":
				setCurrentIndex(3);

				break;
			case "Maritime transport":
				setCurrentIndex(1);
				break;
		}
		return () => {
			window.removeEventListener("storage", () => {});
		};
	}, [openTab]);
	// const prevSlide = () => {
	// 	const isFirstSlide = currentIndex === 0;
	// 	const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
	// 	setCurrentIndex(newIndex);
	// };

	return (
		<div
			className={`nc-SectionHero  relative flex w-full bg-center object-contain  bg-cover flex-col  gap-4 ${className} min-h-[75vh] `}
			data-nc-id="SectionHero"
			style={{
				backgroundImage: `url(${slides[currentIndex].url})`,
				backgroundAttachment: "fixed",

				backgroundRepeat: "no-repeat",

				// WebkitBorderBottomLeftRadius:"0"
			}}
		>
			{/* slider */}
			<div
				className="relative flex-row-reverse items-center max-md:mx-auto"></div>
			<div
				className="container px-0
			"
			>
				<div className=" flex flex-col text-white gap-6 max-w-full m-auto p-0 xl:w-[95%] 2xl:w-[95%]  md:w-[95%] ">
					<span className="text-[24px]    " style={{fontWeight:"400" }} >
						{t("telefreik_header")}
					</span>
					<span className=" text-[32px] " style={{fontWeight:"700", lineHeight:"1"}}>
						{t("telefreik_title").split('-')[0]} -
					</span>
					<span className=" text-[32px] " style={{fontWeight:"700", lineHeight:"1"}}>
						{t("telefreik_title").split('-')[1]}
					</span>
					
				</div>
			</div>
			<div
				className="relative z-10
				 flex w-full flex-col items-center justify-center text-center lg:mb-0 lg:mt-20 lg:justify-end 
			
			"
			>
				<div
					className=" flex w-full justify-center text-justify sm:h-min sm:px-5 
				"
				>
					<HeroSearchForm />
				</div>
			</div>
		</div>
	);
};

export default SectionHero;
