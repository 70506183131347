import { TravelData } from "./types";

export default function sortByArrivalAt(arr: TravelData[]): TravelData[] {
    const sortedArr = [...arr].sort((a, b) => {
        const dateA = new Date(a.arrival_at);
        const dateB = new Date(b.arrival_at);
        return dateB.getTime() - dateA.getTime();
    });

    return sortedArr;
}