import { TravelData } from "./types";
import { format } from 'date-fns'; // Assuming you are using date-fns library for date formatting

export default function exportTravelAtAsDateTime(travelData: TravelData[],type:string ): string[] {
    const uniqueDates: Set<string> = new Set();

    travelData.forEach((data) => {
        if(type === "price"){
            uniqueDates.add(data.prices_start_with.toString());
        }else if( type === "company_name"){
            uniqueDates.add(data.company_data.name.toString());
        }else if( type === "classes_trip"){
            uniqueDates.add(data.classes);
        }else if( type === "departureStations"){
            uniqueDates.add(data.travel_from);
        }else if( type === "arrivalStations"){
            uniqueDates.add(data.travel_to);
        }
        else{
            const formattedDate = format(new Date( type === 'traveling'? data.travel_at :data.arrival_at), 'yyyy-MM-dd HH:mm:ss');
            uniqueDates.add(formattedDate);
        }
    });

    return Array.from(uniqueDates);
}