import React, { FC, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MapAddress from "./MapAddress";
import ButtonClose from "./ButtonClose";
import StepIndicator from "./StepsIndicator";
import DirectionPreviewModal from "./DirectionPreviewModal";
import { updateTimeInDateTime } from "utils/updatetimeInDateTime";
import { addHoursToDateTime } from "utils/addHouresToDateTime";
import { toLocalizedDateString } from "react-dates";
import { parseToDateString } from "utils/toDateFormat";

interface TripDetails {
	id: number;
	company_name: string;
	company_logo: string;
	date: string;
	from_location: { id: number; name: string; name_ar: string; name_en: string };
	to_location: { id: number; name: string; name_ar: string; name_en: string };
	bus: {
		id: number;
		name: string;
		seats_number: string;
		license_plate: string | null;
	};
	rounded: boolean;
	original_price: number;
	discount: string;
	price: number;
	go_price: number;
	round_price: number;
}

interface Address {
	id: number;
	name: string;
	map_location: {
		lat: number | any;
		lng: number | any;
		address_name: string;
	};
}

const ACTIVE_COLOR = "#00939C";

export const PrivateTripOneRoundId: FC = () => {
	const { trip_Id } = useParams<{ trip_Id: string }>();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [data, setData] = useState<TripDetails | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [step, setStep] = useState<1 | 2 | 3 | 4>(1);
	const [pickupAddress, setPickupAddress] = useState<string>("");
	const [destinationAddress, setDestinationAddress] = useState<string>("");
	const [time, setTime] = useState<string>("");
	const [returnDateTime, setReturnDateTime] = useState<string>("");
	const [addresses, setAddresses] = useState<Address[]>([]);
	const [modal, setModal] = useState<"pickup" | "destination" | null>(null);
	const [selectedPickupAddress, setSelectedPickupAddress] =
		useState<Address | null>(null);
	const [selectedPickupAddressData, setSelectedPickupAddressData] =
		useState<any>();
	const [selectedDestinationAddressData, setSelectedDestinationAddressData] =
		useState<any>();
	const [selectedDestinationAddress, setSelectedDestinationAddress] =
		useState<Address | null>(null);
	const [previewPickupAddress, setPreviewPickupAddress] = useState<string>("");
	const [previewDestinationAddress, setPreviewDestinationAddress] =
		useState<string>("");
	const [previewTime, setPreviewTime] = useState<string>("");
	const [returnPreviewTime, setReturnPreviewTime] = useState<string>("");
	const [tripType, setTripType] = useState<string>("oneTrip");
	const [showDirectionModal, setShowDirectionModal] = useState<boolean>(false);
	useEffect(() => {
		if (tripType === "roundTrip") {
			const busEndDate = localStorage.getItem("busEndDate");
			console.log(busEndDate?.slice(1, 12), "busEndDate");
			if (busEndDate) {
				const busEndDateTime =
					busEndDate?.slice(1, busEndDate.length - 1) + "T12:00:00Z";
				setReturnDateTime(busEndDateTime);
				setReturnPreviewTime(busEndDateTime);
				console.log(
					returnDateTime,
					returnPreviewTime,
					"preview initial date time",
				);
			}
		}
	}, [tripType]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				console.log("tryoing to fetch data");
				const response = await axios.get(
					`${process.env.REACT_APP_API_TELE_URL}/api/transports/private/trips/${trip_Id}`,
				);
				console.log("response.data.data", response.data.data);
				setData(response.data.data);
			} catch (error) {
				toast.error("Error fetching trip details");
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchAddresses = async () => {
			try {
				const token = localStorage.getItem("token");
				const config = {
					headers: {
						accept: "application/json, text/plain, */*",
						"accept-language": "en",
						Authorization: `Bearer ${token}`,
					},
				};
				const response = await axios.get(
					`${process.env.REACT_APP_API_TELE_URL}/api/transports/profile/address-book`,
					config,
				);
				setAddresses(response.data.data);
			} catch (error) {
				navigate("/login");
				toast.error("Error fetching addresses");
			}
		};

		fetchAddresses();
	}, []);

	const handleAddressSelect = (
		address: Address,
		type: "pickup" | "destination",
	) => {
		if (type === "pickup") {
			setPickupAddress(address.map_location.address_name);
			setSelectedPickupAddress(address);
			setPreviewPickupAddress(address.map_location.address_name);
			setSelectedPickupAddressData(address);
			setStep(2);
		} else if (type === "destination") {
			setDestinationAddress(address.map_location.address_name);
			setSelectedDestinationAddress(address);
			setSelectedDestinationAddressData(address);
			setPreviewDestinationAddress(address.map_location.address_name);
			setStep(3);
		}
		setModal(null);
	};

	const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedTime = event.target.value;
		console.log(event.target.value);

		setTime(selectedTime);
		setPreviewTime(selectedTime);
	};
	const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedDateTime = event.target.value;
		console.log(event.target.value);
		setReturnDateTime(selectedDateTime);
		setReturnPreviewTime(selectedDateTime);
		console.log(returnDateTime, returnPreviewTime, "date back");
	};
	const tripTipe: any = localStorage.getItem("dropOffLocationType");
	useEffect(() => {
		setTripType(tripTipe);
	}, [tripTipe]);

	console.log("trip tipe is: ", tripTipe);
	const goToSummary = async () => {
		try {
			// Validate required fields
			if (!pickupAddress || !destinationAddress || !time) {
				toast.error("Please fill all required fields");
				return;
			}

			// Fetch token from localStorage
			const token = localStorage.getItem("token");
			if (!token) {
				toast.error("Token not found");
				return;
			}

			// Initialize variables
			let formattedBoardingTime = "";
			let formattedReturnTime = "";

			// Determine date-times based on trip type
			if (tripType === "roundTrip") {
				const privateTwoRoundStartDate = localStorage.getItem(
					"private_twoRound_start_date",
				);
				const busEndDateTime = returnDateTime;

				if (privateTwoRoundStartDate && busEndDateTime) {
					formattedBoardingTime = updateTimeInDateTime(
						privateTwoRoundStartDate,
						previewTime,
					);
					formattedReturnTime = parseToDateString(busEndDateTime);
				} else {
					toast.error("Missing round trip dates");
					return;
				}
			} else {
				// For one-way trips
				const privateOneRoundDateTime = localStorage.getItem(
					"private_oneRound_date_time",
				);
				if (!privateOneRoundDateTime) {
					toast.error("Start date and time not found for one-way trip");
					return;
				}

				formattedBoardingTime = updateTimeInDateTime(
					privateOneRoundDateTime,
					previewTime,
				);
				formattedReturnTime = addHoursToDateTime(formattedBoardingTime, 2);
			}

			// Prepare request payload
			const payload = {
				round: tripType === "roundTrip" ? 2 : 1,
				boarding: {
					date: formattedBoardingTime,
					address_id: selectedPickupAddress?.id,
				},
				return: {
					date:
						tripType === "roundTrip"
							? formattedReturnTime
							: addHoursToDateTime(formattedBoardingTime, 2),
					address_id: selectedDestinationAddress?.id,
				},
			};

			// Send POST request to create ticket
			const response = await axios.post(
				`${process.env.REACT_APP_API_TELE_URL}/api/transports/private/trips/${trip_Id}/create-ticket`,
				payload,
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			);

			// Handle successful response
			toast.success("Created ticket");
			localStorage.setItem("summaryoneround", JSON.stringify(response.data));
			navigate("/private-trip/oneRound/summary");
		} catch (error: any) {
			// Handle error
			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				toast.error(error.response.data.message);
			} else {
				toast.error("Error creating ticket");
			}
		}
	};

	if (loading) {
		return (
			<div className="flex h-screen items-center justify-center">
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	const pickupCenter: [number, number] = selectedPickupAddress
		? [
				selectedPickupAddress.map_location.lat,
				selectedPickupAddress.map_location.lng,
		  ]
		: [30.048888, 31.252194];

	const destinationCenter: [number, number] = selectedDestinationAddress
		? [
				selectedDestinationAddress.map_location.lat,
				selectedDestinationAddress.map_location.lng,
		  ]
		: [30.048888, 31.252194];

	const formatDateTimeLocal = (datetime: any) => {
		const date = new Date(datetime);
		const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
		const localDateTime = new Date(date.getTime() - offset)
			.toISOString()
			.slice(0, 16);
		return localDateTime;
	};
	return (
		<div className="h-[100vh] rounded-lg bg-white p-6 shadow-md">
			<StepIndicator
				step={step}
				pickupAddress={pickupAddress}
				destinationAddress={destinationAddress}
				time={time}
				returnDateTime={returnPreviewTime}
				activeColor="text-blue-600" // Pass your active color
				is_round={tripType === "roundTrip" ? true : false}
			/>

			<div className="mb-4 flex items-center">
				<img
					src={data?.company_logo}
					alt="Company Logo"
					className="h-16 w-16 rounded-full"
				/>
				<div className="ml-4">
					<h2 className="text-2xl font-semibold">{data?.company_name}</h2>
					<p className="text-gray-600">
						{t("trip_date")}: {previewTime}
					</p>
				</div>
			</div>

			{step === 1 && (
				<div className="mb-4">
					<label className="block text-lg font-medium text-gray-700">
					{t('Select Pickup Address')}
					</label>
					<div className="relative">
						<input
							type="text"
							value={pickupAddress}
							onClick={() => setModal("pickup")}
							placeholder="Location"
							readOnly
							className="relative  z-10 block w-[70%] flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 truncate rounded-full border-[1px] border-[#E8ECF2] bg-transparent pl-2 text-left placeholder-[#B9C4D5] focus:placeholder-neutral-300 focus:outline-none focus:ring-0 sm:rounded-[4px] md:h-full xl:text-lg"
						/>

						{/* <ButtonPrimary
              className="absolute inset-y-0 right-0 px-3 py-1"
              onClick={() => setModal('pickup')}
            >
              Select
            </ButtonPrimary> */}
					</div>
				</div>
			)}

			{step === 2 && (
				<div className="mb-4">
					<label className="block text-lg font-medium text-gray-700">
						{t('Select_your_destination_address')}
					</label>
					<div className="relative">
						<input
							type="text"
							value={destinationAddress}
							onClick={() => setModal("destination")}
							placeholder="Location"
							readOnly
							className="relative  z-10 block w-[70%] flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 truncate rounded-full border-[1px] border-[#E8ECF2] bg-transparent pl-2 text-left placeholder-[#B9C4D5] focus:placeholder-neutral-300 focus:outline-none focus:ring-0 sm:rounded-[4px] md:h-full xl:text-lg"
						/>
						{/* <ButtonPrimary
							className="absolute inset-y-0 right-0 px-3 py-1"
							onClick={() => setModal("destination")}
						>
							Select
						</ButtonPrimary> */}
					</div>
				</div>
			)}

			{step === 3 && (
				<div className="relative mb-4">
					<label className="block text-lg font-medium text-gray-700">
						{t('Select Time')}
					</label>
					<div className="relative">
						<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
							<svg
								className="h-4 w-4 text-gray-900 dark:text-gray-400"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="white"
								viewBox="0 0 24 24"
							>
								<path
									fillRule="evenodd"
									d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
						<input
							type="time"
							value={time}
							onChange={handleTimeChange}
							required
							className="z-10  block w-[70%] truncate rounded-full border-[1px] border-[#E8ECF2] bg-gray-50 bg-transparent pl-2 text-left placeholder-[#B9C4D5] focus:placeholder-neutral-300 focus:outline-none focus:ring-0 sm:rounded-[4px] md:h-full xl:text-lg"
						/>
					</div>
				</div>
			)}
			{step === 4 && tripType === "roundTrip" && (
				<div className="mb-4">
					<label className="block text-lg font-medium text-gray-700">
						Select date Time
					</label>
					<input
						type="datetime-local"
						value={formatDateTimeLocal(returnDateTime)}
						onChange={handleDateTimeChange}
						className="z-10  block w-[70%] truncate rounded-full border-[1px] border-[#E8ECF2] bg-gray-50 bg-transparent pl-2 text-left placeholder-[#B9C4D5] focus:placeholder-neutral-300 focus:outline-none focus:ring-0 sm:rounded-[4px] md:h-full xl:text-lg"
					/>
				</div>
			)}

			<div className="flex flex-row items-start  gap-10">
				{step === 1 && (
					<div className="flex flex-col ">
						<ButtonPrimary onClick={() => setStep(2)}>
							{t("Next")}
						</ButtonPrimary>
					</div>
				)}
				{step === 2 && (
					<div  className="flex gap-3 ">
						<ButtonPrimary onClick={() => setStep(1)}>
							{t("Back")}
						</ButtonPrimary>
						<ButtonPrimary onClick={() => setStep(3)}>
							{t("Next")}
						</ButtonPrimary>
					</div>
				)}
				{step === 3 && tripType !== "roundTrip" && (
					<div className="flex  w-full justify-between flex-row max-sm:flex-wrap items-start  ">
            <div className="flex gap-3" >
						<ButtonPrimary onClick={() => setStep(2)}>
							{t("Back")}
						</ButtonPrimary>
						<ButtonPrimary onClick={goToSummary}>{t("Submit")}</ButtonPrimary>
            </div>
            <div className="flex m-auto max-sm:m-0 max-sm:mt-2">
						<ButtonPrimary
							// className="rounded-md bg-blue-500 px-4 py-2 text-white ml-16"
							onClick={() => setShowDirectionModal(true)}
              >
							{t("Preview Direction")}
						</ButtonPrimary>
            </div>
					</div>
				)}
				{step === 3 && tripType === "roundTrip" && (
					<div  className="flex gap-3 ">
						<ButtonPrimary onClick={() => setStep(2)}>
							{t("Back")}
						</ButtonPrimary>
						<ButtonPrimary onClick={() => setStep(4)}>
							{t("Next")}
						</ButtonPrimary>
					</div>
				)}
				{step === 4 && tripType === "roundTrip" && (
					<div  className="flex  w-full justify-between flex-row ">
            <div className="flex gap-3 ">

						<ButtonPrimary onClick={() => setStep(3)}>
							{t("Back")}
						</ButtonPrimary>
						<ButtonPrimary onClick={goToSummary}>{t("Submit")}</ButtonPrimary>
            </div>
            <div className="m-auto p-4">

						<button
							className="rounded-md bg-blue-500 px-4 py-2 text-white"
							onClick={() => setShowDirectionModal(true)}
						>
							{t("Preview Direction")}
						</button>
            </div>

					</div>
				)}
			</div>

			{modal && (
				<div className="fixed inset-0 flex justify-center bg-gray-800  bg-opacity-50 py-20 z-50 ">
					<div className="w-full max-w-md rounded-lg bg-white px-2 py-4 shadow-lg  ">
						{modal === "pickup" && (
							<>
								<h2 className="mb-4 text-lg font-semibold">
									{t('Select Pickup Address')}
								</h2>
								<MapAddress
									addresses={addresses}
									// selectedAddress={selectedPickupAddress}
									// center={[selectedPickupAddress?.map_location.lat,selectedPickupAddress?.map_location.lng]}
									onSelect={address => handleAddressSelect(address, "pickup")}
									// center={pickupCenter}
								/>
							</>
						)}
						{modal === "destination" && (
							<>
								<h2 className="mb-4 text-lg font-semibold">
									Select Destination Address
								</h2>
								<MapAddress
									addresses={addresses}
									// center={ selectedDestinationAddress &&{ lat:selectedDestinationAddress?.map_location.lat,lng:selectedDestinationAddress?.map_location.lng}} // Pass the center prop
									onSelect={address =>
										handleAddressSelect(address, "destination")
									}
								/>
							</>
						)}
						<div className="mt-4 flex justify-end">
							<ButtonClose onClick={() => setModal(null)}>
								{t("Close")}
							</ButtonClose>
						</div>
					</div>
				</div>
			)}
			{selectedPickupAddress && selectedDestinationAddress && (
				<DirectionPreviewModal
					show={showDirectionModal}
					onClose={() => setShowDirectionModal(false)}
					pickupAddress={selectedPickupAddress}
					destinationAddress={selectedDestinationAddress}
				/>
			)}
		</div>
	);
};
