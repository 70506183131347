/**
 * Updates the time portion of a given date-time string or Date object.
 * @param dateTime - The original date-time which can be a Date object or a string in the format 'YYYY-MM-DD HH:mm'.
 * @param newTime - The new time to set in the format 'HH:mm'.
 * @returns The updated date-time string with the new time in 'YYYY-MM-DD HH:mm' format.
 */
export const updateTimeInDateTime = (dateTime: Date | string, newTime: string): string => {
    // console.log("Input dateTime:", `${dateTime}`.slice(1,11))
    // console.log("Input newTime:", newTime);
  
    let date: Date;
  
    if (typeof dateTime === 'string') {
      const [datePart, originalTimePart] = dateTime.split(' ');
    //   console.log("Date part:", datePart);
    //   console.log("Original time part:", originalTimePart);
  
      // If the time part is not provided, default to '00:00'
      const timePart = originalTimePart ? originalTimePart : '00:00';
      
      const [year, month, day] = datePart.slice(1,11).split('-').map(Number);
      const [originalHours, originalMinutes] = timePart.split(':').map(Number);
      
    //   console.log("Parsed year:", year);
    //   console.log("Parsed month:", month);
    //   console.log("Parsed day:", day);
    //   console.log("Original hours:", originalHours);
    //   console.log("Original minutes:", originalMinutes);
  
      // Handle invalid date parts
      if (isNaN(year) || isNaN(month) || isNaN(day)) {
        console.error("Invalid date components.");
        return "Invalid Date";
      }
  
      // Create a Date object from the string
      date = new Date(year, month - 1, day, originalHours, originalMinutes);
      
      // Handle invalid Date object
      if (isNaN(date.getTime())) {
        console.error("Invalid Date object.");
        return "Invalid Date";
      }
    } else {
      // If it's a Date object, use it directly
      date = new Date(dateTime);
    }
  
    // console.log("Parsed Date object:", date);
  
    // Extract hours and minutes from the new time string
    const [newHours, newMinutes] = newTime.split(':').map(Number);
    // console.log("New hours:", newHours);
    // console.log("New minutes:", newMinutes);
  
    // Update the time in the Date object
    date.setHours(newHours, newMinutes);
  
    // Format the updated Date object as a string in 'YYYY-MM-DD HH:mm' format
    const formattedDate = [
      date.getFullYear(),
      String(date.getMonth() + 1).padStart(2, '0'),
      String(date.getDate()).padStart(2, '0')
    ].join('-') + ' ' + [
      String(date.getHours()).padStart(2, '0'),
      String(date.getMinutes()).padStart(2, '0')
    ].join(':');
  
    // console.log("Formatted date-time:", formattedDate);
  
    return formattedDate;
  };