/**
 * Parses a given date-time string and returns a formatted date-time string.
 * @param dateTimeStr - The input string which can be in the format 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD', or ISO 8601 format.
 * @returns A formatted date-time string in 'YYYY-MM-DD HH:mm' format.
 */
export const parseToDateString = (dateTimeStr: string): string => {
    console.log("Input dateTimeStr:", dateTimeStr);

    let date: Date;

    if (typeof dateTimeStr === 'string' && dateTimeStr.trim().length > 0) {
        // Check if the input is in ISO 8601 format
        if (dateTimeStr.includes('T') || dateTimeStr.includes('Z')) {
            date = new Date(dateTimeStr);
            console.log("ISO 8601 Date object:", date);

            // If the date object is invalid
            if (isNaN(date.getTime())) {
                console.error("Invalid Date object.");
                return "Invalid Date";
            }
        } else {
            // Split the input string into date and time parts
            const [datePart, timePart = '00:00'] = dateTimeStr.split(' ');
            console.log("Date part:", datePart);
            console.log("Time part:", timePart);

            // Extract year, month, and day from the date part
            const [year, month, day] = datePart.split('-').map(Number);
            console.log("Parsed year:", year);
            console.log("Parsed month:", month);
            console.log("Parsed day:", day);

            // Extract hours and minutes from the time part
            const [hours, minutes] = timePart.split(':').map(Number);
            console.log("Parsed hours:", hours);
            console.log("Parsed minutes:", minutes);

            // Create a Date object from the extracted values
            date = new Date(year, month - 1, day, hours, minutes);
            console.log("Date object:", date);

            // If the date object is invalid
            if (isNaN(date.getTime())) {
                console.error("Invalid Date object.");
                return "Invalid Date";
            }
        }
    } else {
        console.error("Invalid input string.");
        return "Invalid Date";
    }

    // Format the Date object as a string in 'YYYY-MM-DD HH:mm' format
    const formattedDate = [
        date.getFullYear(),
        String(date.getMonth() + 1).padStart(2, '0'),
        String(date.getDate()).padStart(2, '0')
    ].join('-') + ' ' + [
        String(date.getHours()).padStart(2, '0'),
        String(date.getMinutes()).padStart(2, '0')
    ].join(':');

    console.log("Formatted date-time:", formattedDate);

    return formattedDate;
};
