import React from "react";
import classes from "./Booking.module.css";
import { BackButtom } from "components/BackButtom/BackButtom";
import ProfileCard from "components/ProfileCard/ProfileCard";
import BookingCard from "components/BookingCard/BookingCard";
import { useNavigate } from "react-router-dom";
import Layout from "shared/FaceBook/Layout";
const Booking = () => {
	const	navigate=useNavigate()
	const checkIfUserIsLoggedIn = () => {
		const accessToken = localStorage.getItem("accessToken");
		const itemToken = localStorage.getItem("token");
const isLoggedIn = (accessToken !== null && accessToken.length > 10) || (itemToken !== null && itemToken.length > 10);		
		if (!isLoggedIn) {
		  navigate("/login");
		}
		
		return isLoggedIn ? accessToken : itemToken;
	  };
  
  
	  checkIfUserIsLoggedIn();
	return (
		<Layout>
		<div className=''>
				<div className="h-[30vh] w-[100vw] bg-[#005792]"></div>
			<div className="container translate-y-[-15vw] max-sm:translate-y-[-60vw]">
				<BackButtom  />
				<div className="two mt-24 mb-5 min-h-[80vh] gap-[20px] bg-transparent ">
					<ProfileCard />
					<BookingCard />
				</div>
			</div>
		</div>
		</Layout>
	);
};

export default Booking;
