import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Hero from "images/travelhero2.png";
const Privacy = () => {
	const { t } = useTranslation();
	const renderHeader = () => {
		return (
			<header className="container rounded-xl">
				<div className="mx-auto max-w-screen-md space-y-5">
					<span className=" max-w-4xl text-3xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-4xl md:!leading-[120%] lg:text-4xl ">
						سياسه الخصوصيه
					</span>
					<span className="block pb-1 text-base text-neutral-500 dark:text-neutral-400 md:text-lg">
						{
							<>
								تصف سياسة الخصوصية هذه (“السياسة”) كيف تقوم تلفريك بجمع وحماية
								واستخدام معلومات التعريف الشخصية (“المعلومات الشخصية”) لك
								(“المستخدم” أو “أنت” أو “الخاص بك”) على موقع telefreik.comوأي من
								منتجاته أو خدماتنا (يُشار إليها إجمالاً بـ “الموقع” أو
								“الخدمات”). كما يصف الخيارات المتاحة لك فيما يتعلق باستخدامنا
								لمعلوماتك الشخصية وكيف يمكنك الوصول إلى هذه المعلومات وتحديثها.
								لا تنطبق هذه السياسة على ممارسات الشركات التي لا نملكها أو نتحكم
								فيها، أو على الأفراد الذين لا نوظفهم أو نديرهم
							</>
						}
					</span>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<div
				id="single-entry-content"
				className="dark:prose-dark prose-sm prose mx-auto !max-w-screen-md dark:prose-invert sm:prose lg:prose-lg"
			>
				<div>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						لجمع التلقائي للمعلومات{" "}
					</span>

					<p>
						عندما تزور موقع الويب، تقوم خوادمنا تلقائيًا بتسجيل المعلومات التي
						يرسلها متصفحك. قد تتضمن هذه البيانات معلومات مثل عنوان IP الخاص
						بجهازك، ونوع المتصفح وإصداره، ونوع نظام التشغيل وإصداره، وتفضيلات
						اللغة أو صفحة الويب التي كنت تزورها قبل وصولك إلى موقعنا الإلكتروني،
						وصفحات موقعنا الإلكتروني التي تزورها، والوقت الذي تقضيه في تلك
						الصفحات والمعلومات التي تبحث عنها على موقعنا الإلكتروني وأوقات
						الوصول والتواريخ وإحصائيات أخرى
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						جمع المعلومات الشخصية
					</span>

					<p>
						يمكنك زيارة موقع الويب دون إخبارنا عن هويتك أو الكشف عن أي معلومات
						يمكن من خلالها التعرف عليك كفرد محدد يمكن التعرف عليه. ومع ذلك، إذا
						كنت ترغب في استخدام بعض ميزات موقع الويب، فسيُطلب منك تقديم معلومات
						شخصية معينة (على سبيل المثال، اسمك وعنوان بريدك الإلكتروني). نتلقى
						ونخزن أي معلومات تزودنا بها عن قصد عند إنشاء حساب أو إجراء عملية
						شراء أو ملء أي نماذج عبر الإنترنت على الموقع. عند الاقتضاء، قد تتضمن
						هذه المعلومات عنوان بريدك الإلكتروني أو اسمك أو رقم هاتفك أو عنوانك
						أو معلومات شخصية أخرى. يمكنك اختيار عدم تزويدنا بمعلوماتك الشخصية،
						ولكن بعد ذلك قد لا تتمكن من الاستفادة من بعض ميزات موقع الويب. نرحب
						بالمستخدمين الذين لا يعرفون ما هي المعلومات الإلزامية للاتصال بنا
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						إدارة المعلومات الشخصية{" "}
					</span>

					<p>
						يمكنك الوصول إلى معلومات شخصية معينة عنك والإضافة إليها وتحديثها
						وحذفها. قد تتغير المعلومات التي يمكنك عرضها وتحديثها وحذفها مع تغير
						موقع الويب أو الخدمات. ومع ذلك، عندما تقوم بتحديث المعلومات، فقد
						نحتفظ بنسخة من المعلومات غير المنقحة في سجلاتنا. قد تبقى بعض
						المعلومات في سجلاتنا الخاصة بعد حذفك لهذه المعلومات من حسابك. سنحتفظ
						بمعلوماتك الشخصية ونستخدمها للفترة اللازمة للامتثال لالتزاماتنا
						القانونية، وحل النزاعات، وإنفاذ اتفاقياتنا ما لم يتطلب القانون فترة
						احتفاظ أطول أو يسمح بها. قد نستخدم أي بيانات مجمعة مشتقة من معلوماتك
						الشخصية أو دمجها بعد تحديثها أو حذفها، ولكن ليس بطريقة تحدد هويتك
						الشخصية. بمجرد انتهاء فترة الاحتفاظ، سيتم حذف المعلومات الشخصية.
						لذلك، لا يمكن إنفاذ الحق في الوصول والحق في المسح والحق في التصحيح
						والحق في إمكانية نقل البيانات بعد انتهاء فترة الاحتفاظ
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						استخدام ومعالجة المعلومات التي تم جمعها
					</span>

					<p>
						يمكن استخدام أي من المعلومات التي نجمعها منك لإضفاء الطابع الشخصي
						على تجربتك؛ تحسين موقعنا؛ تحسين خدمة العملاء والرد على استفسارات
						ورسائل البريد الإلكتروني لعملائنا؛ معالجة المعاملات إرسال رسائل
						إخبارية إرسال رسائل بريد إلكتروني للإشعارات مثل التذكير بكلمة المرور
						والتحديثات وما إلى ذلك؛ تشغيل موقعنا وخدماتنا. يتم استخدام المعلومات
						التي يتم جمعها تلقائيًا فقط لتحديد الحالات المحتملة لسوء الاستخدام
						وإنشاء معلومات إحصائية بشأن <br />
						استخدام الموقع. لا يتم تجميع هذه المعلومات الإحصائية بطريقة أخرى من
						شأنها تحديد أي مستخدم معين للنظام. قد نعالج المعلومات الشخصية
						المتعلقة بك إذا كان أحد الإجراءات التالية ينطبق: (1) لقد أعطيت
						موافقتك لهدف أو أكثر من الأغراض المحددة. لاحظ أنه بموجب بعض
						التشريعات، قد يُسمح لنا بمعالجة المعلومات حتى تعترض على هذه المعالجة
						(عن طريق إلغاء الاشتراك)، دون الحاجة إلى الاعتماد على الموافقة أو أي
						من القواعد القانونية التالية أدناه. ومع ذلك، لا ينطبق هذا، عندما
						تخضع معالجة المعلومات الشخصية لقانون حماية البيانات الأوروبي؛ (2)
						تقديم المعلومات ضروري لأداء اتفاقية معك و / أو لأي التزامات تعاقدية
						سابقة لها؛ (3) المعالجة ضرورية للامتثال لالتزام قانوني تخضع له؛ (4)
						تتعلق المعالجة بمهمة يتم تنفيذها للمصلحة العامة أو في ممارسة السلطة
						الرسمية المخولة لنا؛ (5) المعالجة ضرورية لأغراض المصالح المشروعة
						التي نتابعها أو نتابعها بواسطة طرف ثالث. على أي حال، يسعدنا توضيح
						الأساس القانوني المحدد الذي ينطبق على المعالجة، وعلى وجه الخصوص ما
						إذا كان توفير المعلومات الشخصية شرطًا قانونيًا أو تعاقديًا، أو شرطًا
						ضروريًا لإبرام عقد
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						نقل المعلومات وتخزينها{" "}
					</span>

					<p>
						اعتمادًا على موقعك، قد تتضمن عمليات نقل البيانات نقل وتخزين معلوماتك
						في بلد آخر غير بلدك. يحق لك التعرف على الأساس القانوني لنقل
						المعلومات إلى بلد خارج الاتحاد الأوروبي أو إلى أي منظمة دولية يحكمها
						القانون الدولي العام أو أنشأتها دولتان أو أكثر، مثل الأمم المتحدة،
						وحول الإجراءات الأمنية التي اتخذتها لنا لحماية معلوماتك. في حالة
						حدوث أي نقل من هذا القبيل، يمكنك معرفة المزيد عن طريق التحقق من
						الأقسام ذات الصلة من هذا المستند أو الاستفسار معنا باستخدام
						المعلومات الواردة في قسم الاتصال
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						حقوق المستخدمين{" "}
					</span>

					<p>
						يمكنك ممارسة بعض الحقوق فيما يتعلق بمعلوماتك التي نقوم بمعالجتها.
						على وجه الخصوص، لديك الحق في القيام بما يلي: (1) لديك الحق في سحب
						الموافقة حيث سبق لك أن أعطيت موافقتك على معالجة معلوماتك؛ (2) لديك
						الحق في الاعتراض على معالجة معلوماتك إذا تمت المعالجة على أساس
						قانوني بخلاف الموافقة؛ (3) لديك الحق في معرفة ما إذا كانت المعلومات
						قيد المعالجة بواسطتنا، والحصول على إفصاح بشأن جوانب معينة من
						المعالجة والحصول على نسخة من المعلومات
						<br /> التي تخضع للمعالجة؛ (4) لديك الحق في التحقق من دقة المعلومات
						الخاصة بك وطلب تحديثها أو تصحيحها؛ (5) لديك الحق ، في ظل ظروف معينة،
						في تقييد معالجة معلوماتك، وفي هذه الحالة، لن نقوم بمعالجة معلوماتك
						لأي غرض بخلاف تخزينها؛ (6) يحق لك، في ظل ظروف معينة، الحصول على محو
						معلوماتك الشخصية منا؛ (7) لديك الحق في تلقي معلوماتك بتنسيق منظم
						وشائع الاستخدام وقابل للقراءة آليًا، وإذا كان ذلك ممكنًا من الناحية
						الفنية، فيتم نقلها إلى وحدة تحكم أخرى دون أي عائق. يسري هذا الحكم
						شريطة أن تتم معالجة معلوماتك بوسائل آلية وأن المعالجة تستند إلى
						موافقتك، على عقد تكون جزءًا منه أو على التزامات تعاقدية مسبقة منه
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						الحق في الاعتراض على المعالجة{" "}
					</span>

					<p>
						عندما تتم معالجة المعلومات الشخصية للمصلحة العامة، أو في ممارسة سلطة
						رسمية مخولة لنا أو لأغراض المصالح المشروعة التي نتبعها، يمكنك
						الاعتراض على هذه المعالجة من خلال توفير أساس متعلق بموقفك الخاص
						لتبرير اعتراض. يجب أن تعلم أنه في حالة معالجة معلوماتك الشخصية
						لأغراض التسويق المباشر، يمكنك الاعتراض على هذه المعالجة في أي وقت
						دون تقديم أي مبرر. لمعرفة ما إذا كنا نعالج المعلومات الشخصية لأغراض
						التسويق المباشر، يمكنك الرجوع إلى الأقسام ذات الصلة من هذا المستند
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						كيف تمارس هذه الحقوق{" "}
					</span>

					<p>
						يمكن توجيه أي طلبات لممارسة حقوق المستخدم إلى المالك من خلال تفاصيل
						الاتصال الواردة في هذا المستند. يمكن ممارسة هذه الطلبات مجانًا وسيتم
						معالجتها من قبل المالك في أقرب وقت ممكن
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						الفواتير والدفعات{" "}
					</span>

					<p>
						نحن نستخدم معالجات دفع تابعة لجهات خارجية لمساعدتنا في معالجة
						معلومات الدفع الخاصة بك بشكل آمن. يخضع استخدام معالجات الجهات
						الخارجية لمعلوماتك الشخصية لسياسات الخصوصية الخاصة بكل منها والتي قد
						تحتوي أو لا تحتوي على حماية الخصوصية كحماية مثل سياسة الخصوصية هذه.
						نقترح عليك مراجعة سياسات الخصوصية الخاصة بكل منها.
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						خصوصية الأطفال{" "}
					</span>

					<p>
						نحن لا نجمع أي معلومات شخصية عن قصد من الأطفال الذين تقل أعمارهم عن
						13 عامًا. إذا كان عمرك أقل من 13 عامًا، فيرجى عدم إرسال أي معلومات
						شخصية من خلال موقعنا أو خدمتنا. نحن نشجع الآباء والأوصياء القانونيين
						على مراقبة استخدام أطفالهم للإنترنت وللمساعدة في تطبيق هذه السياسة
						من خلال توجيه أطفالهم إلى عدم تقديم معلومات شخصية من خلال موقعنا
						الإلكتروني أو خدمتنا دون إذن منهم. إذا كان لديك سبب للاعتقاد بأن
						طفلًا يقل عمره عن 13 عامًا قد قدم لنا معلومات شخصية من خلال موقعنا
						أو خدمتنا، فيرجى الاتصال بنا. يجب أيضًا أن يكون عمرك 16 عامًا على
						الأقل للموافقة على معالجة معلوماتك الشخصية في بلدك (في بعض البلدان
						قد نسمح لوالدك أو ولي أمرك بالقيام بذلك نيابة عنك).
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						النشرات الإخبارية{" "}
					</span>

					<p>
						نقدم رسائل إخبارية إلكترونية يمكنك الاشتراك فيها طواعية في أي وقت.
						يمكنك اختيار إيقاف تلقي رسائلنا الإخبارية أو رسائل البريد الإلكتروني
						التسويقية باتباع إرشادات إلغاء الاشتراك الواردة في رسائل البريد
						الإلكتروني هذه أو عن طريق الاتصال بنا. ومع ذلك، ستستمر في تلقي رسائل
						البريد الإلكتروني الأساسية الخاصة بالمعاملات
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						ملفات تعريف الارتباط{" "}
					</span>

					<p>
						يستخدم موقع الويب “ملفات تعريف الارتباط” للمساعدة في تخصيص تجربتك
						على الإنترنت. ملف تعريف الارتباط هو ملف نصي يتم وضعه على القرص
						الثابت بواسطة خادم صفحة ويب. لا يمكن استخدام ملفات تعريف الارتباط
						لتشغيل البرامج أو توصيل الفيروسات إلى جهاز الكمبيوتر الخاص بك. يتم
						تخصيص ملفات تعريف الارتباط لك بشكل فريد، ولا يمكن قراءتها إلا بواسطة
						خادم ويب في المجال الذي أصدر ملف تعريف الارتباط لك. قد نستخدم ملفات
						تعريف الارتباط لجمع المعلومات وتخزينها وتتبعها لأغراض إحصائية لتشغيل
						موقعنا وخدماتنا. لديك القدرة على قبول أو رفض ملفات تعريف الارتباط.
						تقبل معظم متصفحات الويب ملفات تعريف الارتباط تلقائيًا، ولكن يمكنك
						عادةً تعديل إعدادات المتصفح لرفض ملفات تعريف الارتباط إذا كنت تفضل
						ذلك. لمعرفة المزيد حول ملفات تعريف الارتباط وكيفية إدارتها، تفضل
						بزيارة internetcookies.org بالإضافة إلى استخدام ملفات تعريف الارتباط
						والتقنيات ذات الصلة كما هو موضح أعلاه، قد نسمح أيضًا لبعض الشركات
						التابعة لجهات خارجية بمساعدتنا في تصميم الإعلانات التي نعتقد أنها قد
						تكون ذات فائدة للمستخدمين ولجمع واستخدام بيانات أخرى حول أنشطة
						المستخدم على الموقع. قد تقدم هذه الشركات إعلانات قد تضع أيضًا ملفات
						تعريف الارتباط وتتبع سلوك المستخدم.
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						لا تتبع الإشارات{" "}
					</span>

					<p>
						تتضمن بعض المتصفحات ميزة “عدم التعقب” التي تشير إلى مواقع الويب التي
						تزورها بأنك لا تريد تتبع نشاطك على الإنترنت. لا يعد التتبع مثل
						استخدام المعلومات أو جمعها فيما يتعلق بموقع ويب. لهذه الأغراض، يشير
						التتبع إلى جمع معلومات التعريف الشخصية من المستهلكين الذين يستخدمون
						أو يزورون موقعًا إلكترونيًا أو خدمة عبر الإنترنت أثناء تنقلهم عبر
						مواقع الويب المختلفة بمرور الوقت. كيفية توصيل المتصفحات لإشارة “عدم
						التعقب” ليست موحدة بعد. نتيجة لذلك، لم يتم إعداد موقع الويب هذا حتى
						الآن لتفسير إشارات عدم التتبع التي يرسلها متصفحك أو الاستجابة لها.
						ومع ذلك، كما هو موضح بمزيد من التفصيل في هذه السياسة، فإننا نقيد
						استخدامنا وجمع معلوماتك الشخصية
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						روابط لمواقع أخرى{" "}
					</span>

					<p>
						يحتوي موقعنا على روابط لمواقع أخرى لا نملكها ولا نتحكم فيها. يرجى
						العلم أننا لسنا مسؤولين عن ممارسات الخصوصية لهذه المواقع الأخرى أو
						الأطراف الثالثة. نشجعك على أن تكون على دراية عندما تغادر موقعنا
						الإلكتروني وأن تقرأ بيانات الخصوصية لكل موقع ويب قد يجمع معلومات
						شخصية
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						أمن المعلومات{" "}
					</span>

					<p>
						نقوم بتأمين المعلومات التي تقدمها على خوادم الكمبيوتر في بيئة آمنة
						ومحكومة ومحمية من الوصول أو الاستخدام أو الكشف غير المصرح به. نحافظ
						على ضمانات إدارية وفنية ومادية معقولة في محاولة للحماية من الوصول
						غير المصرح به إلى المعلومات الشخصية واستخدامها وتعديلها والكشف عنها
						في سيطرتها وحفظها. ومع ذلك، لا يمكن ضمان نقل البيانات عبر الإنترنت
						أو الشبكة اللاسلكية. لذلك، بينما نسعى جاهدين لحماية معلوماتك
						الشخصية، فإنك تقر بأن (1) توجد قيود على الأمان والخصوصية للإنترنت
						خارجة عن سيطرتنا؛ (2) لا يمكن ضمان أمن وسلامة وخصوصية أي وجميع
						المعلومات والبيانات المتبادلة بينك وبين موقعنا؛ و (3) قد يتم عرض أي
						من هذه المعلومات والبيانات أو العبث بها أثناء النقل من قبل طرف ثالث،
						على الرغم من بذل أقصى الجهود
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						خرق البيانات{" "}
					</span>

					<p>
						في حال علمنا أن أمان الموقع قد تعرض للخطر أو تم الكشف عن معلومات
						المستخدمين الشخصية لأطراف ثالثة غير ذات صلة نتيجة لنشاط خارجي، بما
						في ذلك، على سبيل المثال لا الحصر، الهجمات الأمنية أو الاحتيال، فإننا
						نحتفظ بالحق في اتخاذ التدابير المناسبة بشكل معقول، بما في ذلك، على
						سبيل المثال لا الحصر، التحقيق والإبلاغ، وكذلك إخطار سلطات إنفاذ
						القانون والتعاون معها. في حالة حدوث خرق للبيانات، سنبذل جهودًا
						معقولة لإخطار الأفراد المتأثرين إذا اعتقدنا أن هناك خطرًا معقولًا
						لإلحاق ضرر بالمستخدم نتيجة للانتهاك أو إذا كان الإشعار مطلوبًا بخلاف
						ذلك بموجب القانون. عندما نقوم بذلك، سوف نرسل لك بريدًا إلكترونيًا.
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						التغييرات والتعديلات{" "}
					</span>

					<p>
						قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر وفقًا لتقديرنا وسنخطرك
						بأي تغييرات جوهرية في الطريقة التي نتعامل بها مع المعلومات الشخصية.
						عند إجراء التغييرات، سنقوم بمراجعة التاريخ المحدث في أسفل هذه
						الصفحة. قد نقدم أيضًا إشعارًا لك بطرق أخرى وفقًا لتقديرنا، مثل
						معلومات الاتصال التي قدمتها. ستكون أي نسخة محدثة من سياسة الخصوصية
						هذه سارية فور نشر سياسة الخصوصية المعدلة ما لم ينص على خلاف ذلك. إن
						استمرارك في استخدام الموقع أو الخدمات بعد تاريخ سريان سياسة الخصوصية
						المنقحة (أو أي عمل آخر محدد في ذلك الوقت) سيشكل موافقتك على هذه
						التغييرات. ومع ذلك، لن نستخدم بياناتك الشخصية، بدون موافقتك، بطريقة
						تختلف جوهريًا عما تم ذكره في وقت جمع بياناتك الشخصية
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						قبول هذه السياسة{" "}
					</span>

					<p>
						أنت تقر بأنك قد قرأت هذه السياسة وتوافق على جميع الشروط والأحكام
						الخاصة بها. باستخدام موقع الويب أو خدماته، فإنك توافق على الالتزام
						بهذه السياسة. إذا كنت لا توافق على الالتزام بشروط هذه السياسة، فأنت
						غير مصرح لك باستخدام أو الوصول إلى موقع الويب وخدماته
					</p>
					<span className=" max-w-4xl text-xl font-semibold text-neutral-900 dark:text-neutral-100 md:text-3xl md:!leading-[120%] lg:text-2xl ">
						الاتصال بنا{" "}
					</span>

					<p>
						إذا كنت ترغب في الاتصال بنا لفهم المزيد حول هذه السياسة أو ترغب في
						الاتصال بنا بخصوص أي مسألة تتعلق بالحقوق الفردية ومعلوماتك الشخصية،
						يمكنك القيام بذلك عبر نموذج الاتصال
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className="nc-PageSingle pt-8 lg:pt-16 ">
			<Helmet>
				<title> Privacy || Telefreik For Booking</title>
			</Helmet>
			{renderHeader()}
		

			<div className="nc-SingleContent container space-y-10">
				{renderContent()}
			</div>
		</div>
	);
};

export default Privacy;
