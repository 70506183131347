const loadCairoFont = (): void => {
    const fontStyles = `
      @font-face {
        font-family: 'Cairo';
        src: url(${require('../fonts/Cairo-VariableFont_slnt,wght.ttf')}) format('truetype');
        font-style: normal;
      }
  
      /* Apply the Cairo font to all elements */
      * {
        font-family: 'Cairo', sans-serif;
      }
    `;
  
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(fontStyles));
    document.head.appendChild(styleElement);
  };
  
  export default loadCairoFont;