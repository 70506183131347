import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { PathName } from "routers/types";

interface SolutionItem {
	name: string;
	description: string;
	href: PathName;
	icon: any;
	active?: boolean;
}

const solutions: SolutionItem[] = [
	{
		name: "Stays",
		description: "Stays rental description ",
		href: "/listing-stay",
		icon: IconFour,
		active: true,
	},
	{
		name: "Flights",
		description: "Flights description",
		href: "/listing-flights",
		icon: IconTwo,
	},
	{
		name: "Things to do",
		description: "Tour and experiences",
		href: "/listing-experiences",
		icon: IconOne,
	},
	{
		name: "Cars",
		description: "Car rental description",
		href: "/listing-car",
		icon: IconThree,
	},
];

export default function DropdownTravelers() {
	return (
		<div className="DropdownTravelers">
			<Popover className="relative">
				{({ open, close }) => (
					<>
						<Popover.Button
							className={`${open ? "" : "text-opacity-90"}
                group rounded-md py-2 text-sm font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 sm:text-base`}
						>
							<div className={` inline-flex items-center `} role="button">
								<span>Travelers</span>
								<ChevronDownIcon
									className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-2 h-5 w-5 text-neutral-700 transition duration-150 ease-in-out group-hover:text-opacity-80 `}
									aria-hidden="true"
								/>
							</div>
						</Popover.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-200"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel className="absolute left-1/2 z-40 mt-4 w-screen max-w-xs -translate-x-1/2 transform px-4 sm:px-0">
								<div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
									<div className="relative grid grid-cols-1 gap-8 bg-white p-7 dark:bg-neutral-800 ">
										{solutions.map((item, index) => (
											<Link
												key={index}
												to={item.href}
												onClick={() => close()}
												className={`-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 dark:hover:bg-neutral-700 ${
													item.active
														? "bg-neutral-100 dark:bg-neutral-700"
														: ""
												}`}
											>
												<div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-primary-50 text-primary-500 sm:h-12 sm:w-12">
													<item.icon aria-hidden="true" />
												</div>
												<div className="ml-4 space-y-0.5">
													<p className="text-sm font-medium ">{item.name}</p>
													<p className="text-xs text-neutral-500 dark:text-neutral-300">
														{item.description}
													</p>
												</div>
											</Link>
										))}
									</div>
									{/* FOOTER */}
									<div className="bg-neutral-50 p-4 dark:bg-neutral-700">
										<Link
											to="##"
											className="flow-root space-y-0.5 rounded-md px-2 py-2 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
										>
											<span className="flex items-center">
												<span className="text-sm font-medium ">
													Documentation
												</span>
											</span>
											<span className="block text-sm text-gray-500 dark:text-neutral-400">
												Start integrating products and tools
											</span>
										</Link>
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		</div>
	);
}

function IconOne() {
	return (
		<svg width="36" height="36" fill="none" viewBox="0 0 24 24">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M4.75 8.75C4.75 7.64543 5.64543 6.75 6.75 6.75H17.25C18.3546 6.75 19.25 7.64543 19.25 8.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H6.75C5.64543 19.25 4.75 18.3546 4.75 17.25V8.75Z"
			></path>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M8 4.75V8.25"
			></path>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M16 4.75V8.25"
			></path>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M7.75 10.75H16.25"
			></path>
		</svg>
	);
}

function IconTwo() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			stroke="currentColor"
			className="h-8 w-8"
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
			/>
		</svg>
	);
}

function IconThree() {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.51 2.82996H8.49C6 2.82996 5.45 4.06996 5.13 5.58996L4 11H20L18.87 5.58996C18.55 4.06996 18 2.82996 15.51 2.82996Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.99 19.82C22.1 20.99 21.16 22 19.96 22H18.08C17 22 16.85 21.54 16.66 20.97L16.46 20.37C16.18 19.55 16 19 14.56 19H9.43998C7.99998 19 7.78998 19.62 7.53998 20.37L7.33998 20.97C7.14998 21.54 6.99998 22 5.91998 22H4.03998C2.83998 22 1.89998 20.99 2.00998 19.82L2.56998 13.73C2.70998 12.23 2.99998 11 5.61998 11H18.38C21 11 21.29 12.23 21.43 13.73L21.99 19.82Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 8H3"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 8H20"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 3V5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.5 5H13.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 15H9"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 15H18"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
function IconFour() {
	return (
		<svg
			width="36"
			height="36"
			stroke="currentColor"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M6.75024 19.2502H17.2502C18.3548 19.2502 19.2502 18.3548 19.2502 17.2502V9.75025L12.0002 4.75024L4.75024 9.75025V17.2502C4.75024 18.3548 5.64568 19.2502 6.75024 19.2502Z"
			></path>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.5"
				d="M9.74963 15.7493C9.74963 14.6447 10.6451 13.7493 11.7496 13.7493H12.2496C13.3542 13.7493 14.2496 14.6447 14.2496 15.7493V19.2493H9.74963V15.7493Z"
			></path>
		</svg>
	);
}
