// ButtonClose.tsx
import React from 'react';

interface ButtonCloseProps {
  onClick: () => void;
  className?: string;
  children?: React.ReactNode; // Add children to the props
}

const ButtonClose: React.FC<ButtonCloseProps> = ({ onClick, className = '', children }) => {
  return (
    <button onClick={onClick} className={`btn-close ${className} rounded-md bg-amber-800 px-4 py-2 text-white`}>
      {children}
    </button>
  );
};

export default ButtonClose;
