import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

// <--- NavItemType --->
export interface MegamenuItem {
	id: string;
	image: string;
	title: string;
	items: NavItemType[];
}
export interface NavItemType {
	id: string;
	name: string;
	isNew?: boolean;
	href: string;
	targetBlank?: boolean;
	children?: NavItemType[];
	megaMenu?: MegamenuItem[];
	type?: "dropdown" | "megaMenu" | "none";
}

export interface NavigationItemProps {
	menuItem: NavItemType;
}

type NavigationItemWithRouterProps = NavigationItemProps;

const NavigationItem: FC<NavigationItemWithRouterProps> = ({ menuItem }) => {
	const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);
	const { t } = useTranslation();

	// CLOSE ALL MENU OPENING WHEN CHANGE HISTORY
	const locationPathName = useLocation().pathname;
	useEffect(() => {
		setMenuCurrentHovers([]);
	}, [locationPathName]);

	const onMouseEnterMenu = (id: string) => {
		setMenuCurrentHovers(state => [...state, id]);
	};

	const onMouseLeaveMenu = (id: string) => {
		setMenuCurrentHovers(state => {
			return state.filter((item, index) => {
				return item !== id && index < state.indexOf(id);
			});
		});
	};

	// ===================== MENU MEGAMENU =====================
	const renderMegaMenu = (menu: NavItemType) => {
		const isHover = menuCurrentHovers.includes(menu.id);
		const isFull = menu.megaMenu && menu.megaMenu?.length > 3;
		const classPopover = isFull
			? "menu-megamenu--large"
			: "menu-megamenu--small relative";
		const classPanel = isFull ? "left-0" : "-translate-x-1/2 left-1/2";

		return (
			<Popover
				as="li"
				className={`menu-item menu-megamenu space-x-2 ${classPopover} `}
				onMouseEnter={() => onMouseEnterMenu(menu.id)}
				onMouseLeave={() => onMouseLeaveMenu(menu.id)}
			>
				{() => (
					<>
						<div>{renderMainItem(menu)}</div>
						<Transition
							as={Fragment}
							show={isHover}
							enter="transition ease-out duration-150"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel
								static
								className={`nc-will-change-transform sub-menu absolute z-10 w-screen max-w-sm transform px-4 pt-3 sm:px-0 lg:max-w-max ${classPanel}`}
							>
								<div className="overflow-hidden rounded-lg text-sm shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10">
									<div
										className={`relative grid gap-1 bg-white px-3 py-6 dark:bg-neutral-900 grid-cols-${menu.megaMenu?.length}`}
									>
										{menu.megaMenu?.map(item => (
											<div key={item.id}>
												<div className="px-2">
													<NcImage
														containerClassName="w-36 h-24 rounded-lg overflow-hidden relative flex"
														src={item.image}
													/>
												</div>
												<p className="my-2 px-2 py-1 font-medium text-neutral-900 dark:text-neutral-200">
													{t(item.title)}
												</p>
												<ul className="grid space-y-1">
													{item.items.map(renderMegaMenuNavlink)}
												</ul>
											</div>
										))}
									</div>
								</div>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		);
	};

	const renderMegaMenuNavlink = (item: NavItemType) => {
		return (
			<li key={item.id}>
				<NavLink
					end
					target={item.targetBlank ? "_blank" : undefined}
					rel="noopener noreferrer"
					className={({ isActive }) =>
						`inline-flex items-center rounded py-1 px-2 font-normal text-neutral-6000 hover:bg-neutral-100 hover:text-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 `
					}
					to={item.href || ""}
				>
					{t(item.name)}
				</NavLink>
			</li>
		);
	};

	// ===================== MENU DROPDOW =====================
	const renderDropdownMenu = (menuDropdown: NavItemType) => {
		const isHover = menuCurrentHovers.includes(menuDropdown.id);
		return (
			<Popover
				as="li"
				className={`menu-item menu-dropdown relative ${
					menuDropdown.isNew ? "menuIsNew_lv1" : ""
				}`}
				onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
				onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
			>
				{() => (
					<>
						<div>{renderMainItem(menuDropdown)}</div>
						<Transition
							as={Fragment}
							show={isHover}
							enter="transition ease-out duration-150 "
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel
								static
								className="sub-menu nc-will-change-transform absolute left-0 z-10 w-56 transform pt-3"
							>
								<ul className="relative grid space-y-1 rounded-lg bg-white py-4 text-sm shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-neutral-900 dark:ring-white dark:ring-opacity-10">
									{menuDropdown.children?.map(i => {
										if (i.type) {
											return renderDropdownMenuNavlinkHasChild(i);
										} else {
											return (
												<li
													key={i.id}
													className={`px-2 ${i.isNew ? "menuIsNew" : ""}`}
												>
													{renderDropdownMenuNavlink(i)}
												</li>
											);
										}
									})}
								</ul>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		);
	};

	const renderDropdownMenuNavlinkHasChild = (item: NavItemType) => {
		const isHover = menuCurrentHovers.includes(item.id);
		return (
			<Popover
				as="li"
				key={item.id}
				className="menu-item menu-dropdown relative px-2"
				onMouseEnter={() => onMouseEnterMenu(item.id)}
				onMouseLeave={() => onMouseLeaveMenu(item.id)}
			>
				{() => (
					<>
						<div>{renderDropdownMenuNavlink(item)}</div>
						<Transition
							as={Fragment}
							show={isHover}
							enter="transition ease-out duration-150"
							enterFrom="opacity-0 translate-y-1"
							enterTo="opacity-100 translate-y-0"
							leave="transition ease-in duration-150"
							leaveFrom="opacity-100 translate-y-0"
							leaveTo="opacity-0 translate-y-1"
						>
							<Popover.Panel
								static
								className="sub-menu absolute top-0 left-full z-10 w-56 pl-2"
							>
								<ul className="relative grid space-y-1 rounded-lg bg-white py-4 text-sm shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-neutral-900 dark:ring-white dark:ring-opacity-10">
									{item.children?.map(i => {
										if (i.type) {
											return renderDropdownMenuNavlinkHasChild(i);
										} else {
											return (
												<li key={i.id} className="px-2">
													{renderDropdownMenuNavlink(i)}
												</li>
											);
										}
									})}
								</ul>
							</Popover.Panel>
						</Transition>
					</>
				)}
			</Popover>
		);
	};

	const renderDropdownMenuNavlink = (item: NavItemType) => {
		return (
			<NavLink
				end
				target={item.targetBlank ? "_blank" : undefined}
				rel="noopener noreferrer"
				className={({ isActive }) =>
					`flex items-center rounded-md py-2 px-4 font-normal text-neutral-6000 hover:bg-neutral-100 hover:text-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 ${
						isActive ? "!font-medium  dark:!text-neutral-100" : ""
					}`
				}
				to={item.href || ""}
			>
				{t(`${item.name}`)}
				{item.type && (
					<ChevronDownIcon
						className="ml-2 h-4 w-4 text-neutral-500"
						aria-hidden="true"
					/>
				)}
			</NavLink>
		);
	};

	// ===================== MENU MAIN MENU =====================
	const renderMainItem = (item: NavItemType) => {
		return (
			<NavLink
				end
				target={item.targetBlank ? "_blank" : undefined}
				rel="noopener noreferrer"
				className={({ isActive }) =>
					` inline-flex items-center rounded-full py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 xl:px-5 xl:text-base ${
						isActive
							? " bg-neutral-100 !font-semibold !text-neutral-900 dark:bg-neutral-800 dark:!text-neutral-100"
							: ""
					}`
				}
				to={item.href || "/"}
			>
				{t(`${item.name}`)}
			</NavLink>
		);
	};

	switch (menuItem.type) {
		case "megaMenu":
			return renderMegaMenu(menuItem);
		case "dropdown":
			return renderDropdownMenu(menuItem);
		default:
			return <li className="menu-item mx-2">{renderMainItem(menuItem)}</li>;
	}
};
// Your component own properties

export default NavigationItem;
