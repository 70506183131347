import React, { useEffect, useState } from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import logoImage from "images/logos/Safaria-02.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [widgetMenus, setWidgetMenus] = useState<WidgetFooterMenu[]>([
        {
            id: "1",
            title: t("explore"),
            menus: [{ href: "/bus", label: t("Bus") }],
        },
        {
            id: "2",
            title: t("resources"),
            menus: [
                { href: "/terms", label: t("terms") },
                { href: "/privacy", label: t("privacy") },
                { href: "/faqs", label: t("faqs") },
            ],
        },
        {
            id: "4",
            title: t("getInTouch"),
            menus: [
                { href: "/contact", label: t("contactUs") },
                { href: "/about", label: t("whoWeAre") },
            ],
        },
    ]);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await fetch('https://app.telefreik.com/api/v1/pages', {
                    headers: {
                        'Accept-Language': i18n.language === 'ar' ? 'ar' : 'en',
                    },
                });
                const data = await response.json();
                if (data.status === 200) {
                 // console.log(data, 'data');
					
                    const pages = data.data
                        .filter((page: any) => page.title.toLowerCase())
                        .map((page: any) => ({
                            href: `/pages/${page.slug}`,
                            label: page.title,
                        }));

                    setWidgetMenus((prevMenus) => {
                        return prevMenus.map((menu) =>
                            menu.id === "2"
                                ? { ...menu, menus: pages }
                                : menu
                        );
                    });
                }
            } catch (error) {
                console.error("Error fetching pages:", error);
            }
        };

        fetchPages();
    }, [i18n.language]);

    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm mt-[20px]">
                <span className="text-white sm:mt-0 font-[500] text-[19px]">{menu.title}</span>
                <ul className="space-y-5">
                    {menu.menus.map((item, index) => (
                        <li key={index} className="mt-3">
                            {!!item?.href ? (
                                <Link
                                    key={index}
                                    className="text-[10px] text-white sm:text-[12px]"
                                    to={item?.href}
                                >
                                    {item.label}
                                </Link>
                            ) : (
                                <p className="text-[10px] text-white sm:text-[12px]">
                                    {item.label}
                                </p>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="h-fit pt-[30px] bg-[#003399] text-[#FFFFFF] absolute w-full">
            <div className="container gap-y-10 gap-x-5 sm:grid sm:grid-cols-2 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10">
                <div className="col-span-2 grid gap-5 sm:grid-cols-4 md:col-span-4 lg:md:col-span-1 lg:relative lg:flex lg:flex-col">
                    <div className="mx-auto h-[174px] w-[285px] md:col-span-1 lg:absolute lg:top-[-25px] lg:left-[-40px]">
                        <img src={logoImage} alt="logo footer" />
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
            </div>
            <div className="flex flex-col items-center justify-center gap-4 px-4 py-8">
                <SocialsList1 className="flex flex-row items-center space-x-3 lg:flex-row lg:items-center lg:space-x-0 lg:space-y-2.5" />
                <p className="text-sm text-white">{t("footerDec")} </p>
                <span>©{t("copyRight", { year: new Date().getFullYear() })}</span>
            </div>
        </div>
    );
};

export default Footer;
