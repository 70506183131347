import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { EyeIcon } from '@heroicons/react/24/outline'; // Import Eye icon from Heroicons

// Import your local marker images
import markerIconUrl from './favicon.svg'; // Icon for saved addresses
import previewIconUrl from './favicon.svg'; // Icon for draggable preview marker
import ModalAsk from './ModalAsk'; // Import the Modal component
import AddressPreview from './AddressPreview';

interface Address {
  id: number;
  name: string;
  map_location: {
    lat: number;
    lng: number;
    address_name: string;
  };
}

interface MapAddressProps {
  addresses: Address[];
  selectedAddress?: Address | null;
  onSelect: (address: Address) => void;
  center?: [number, number]; // Make center optional
}

const defaultCenter: [number, number] = [30.802, 26.814]; // Default to Egypt's coordinates
const egyptBoundingBox = {
  southWest: [22.0, 25.0], // Approximate bounding box for Egypt
  northEast: [32.0, 35.0],
};

const MapAddress: React.FC<MapAddressProps> = ({ addresses, selectedAddress, onSelect, center }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [previewLocation, setPreviewLocation] = useState<L.LatLng | null>(null);
  const [previewName, setPreviewName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(13); // Default zoom level
  const [showPreviewButton, setShowPreviewButton] = useState<boolean>(false); // State for preview button
  const [resultsVisible, setResultsVisible] = useState<boolean>(true); // State for results visibility

  // Custom icons for markers
  const customIcon = new L.Icon({
    iconUrl: markerIconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const previewIcon = new L.Icon({
    iconUrl: previewIconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  // Handle address search using Nominatim API
  const handleSearch = async (address: string) => {
    setSearchTerm(address);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${encodeURIComponent(
          address
        )}&format=jsonv2&limit=5&bounded=1&viewbox=${egyptBoundingBox.southWest[1]},${egyptBoundingBox.southWest[0]},${egyptBoundingBox.northEast[1]},${egyptBoundingBox.northEast[0]}`
      );
      const data = await response.json();
      setSearchResults(data);

      if (data.length > 0) {
        const { lat, lon } = data[0];
        setPreviewLocation(new L.LatLng(parseFloat(lat), parseFloat(lon)));
        setPreviewName(address);
        setShowPreviewButton(true); // Show preview button if results are available
        setResultsVisible(true); // Show results list
      } else {
        console.error('No results found');
        setShowPreviewButton(false); // Hide preview button if no results
        setResultsVisible(false); // Hide results list
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  // Handle saving the address
  const handleSaveAddress = () => {
    if (previewLocation) {
      setIsModalOpen(true);
    }
  };

  // Save address through modal
  const handleModalSave = async (name: string) => {
    if (previewLocation) {
      const newAddress = {
        name: name,
        map_location: {
          lat: previewLocation.lat.toString(),
          lng: previewLocation.lng.toString(),
          address_name: name,
        },
      };

      // Retrieve the token from local storage
      const token = localStorage.getItem('token');

      try {
        const response = await fetch('https://app.telefreik.com/api/transports/profile/address-book', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newAddress),
        });

        if (response.ok) {
          const responseData = await response.json();
          const savedAddress = {
            id: responseData.data.id,
            name: responseData.data.name,
            map_location: {
              lat: responseData.data.map_location.lat,
              lng: responseData.data.map_location.lng,
              address_name: responseData.data.map_location.address_name,
            },
          };

       // console.log('Address saved successfully:', savedAddress);
          onSelect(savedAddress);
        } else {
          console.error('Failed to save address:', await response.text());
        }
      } catch (error) {
        console.error('Error saving address:', error);
      }

      setIsModalOpen(false);
    }
  };

  // Handle map click events
  const MapEvents = () => {
    useMapEvents({
      click: (event) => {
        setPreviewLocation(event.latlng);
        setPreviewName(searchTerm);
      },
    });
    return null;
  };

  // Center the map on a new location
  const MapCenter = ({ position }: { position: L.LatLng | null }) => {
    const map = useMap();
    React.useEffect(() => {
      if (position) {
        map.setView(position, zoomLevel);
      }
    }, [position, map, zoomLevel]);
    return null;
  };

  // Fallback center if no center provided
  const mapCenter = center || defaultCenter;

  // Function to zoom out

  // Function to preview search results
  const handlePreviewSearchResults = () => {
    if (searchResults.length > 0) {
      const { lat, lon } = searchResults[0];
      setPreviewLocation(new L.LatLng(parseFloat(lat), parseFloat(lon)));
      setResultsVisible(false); // Hide results list after previewing
    }
  };
  const handleAddressClick = (address: Address) => {
    // Extract latitude and longitude from the selected address
    const { lat, lng } = address.map_location;
    
    // Update the preview location and name
    setPreviewLocation(new L.LatLng(lat, lng));
    setPreviewName(address.name);
    
    // Call the onSelect callback to handle the selected address
    onSelect(address);
  };
    
  return (
    <div>
      {!isModalOpen && (
        <>
          <div className="text-center mb-4 relative h-auto overflow-auto">
          <AddressPreview addresses={addresses} onAddressClick={handleAddressClick} />
            <p className="text-gray-600">Click on any marker to select it as your pickup location.</p>
            <input
              type="text"
              placeholder="Search for a place"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-300 p-2 rounded-md"
            />
            {resultsVisible && searchResults.length > 0 && (
              <>
                <ul className="mt-4 border border-gray-300 rounded-md ">
                  {searchResults.map((result, index) => (
                    <li
                      key={index}
                      className="p-2 cursor-pointer hover:bg-gray-100 flex items-center justify-between"
                      onClick={() => {
                        const latlng = new L.LatLng(parseFloat(result.lat), parseFloat(result.lon));
                        setPreviewLocation(latlng);
                        setPreviewName(result.display_name || 'Unnamed');
                        setResultsVisible(false); // Hide results list after selection
                      }}
                    >
                      <span>{result.display_name}</span>
                      <EyeIcon className="w-5 h-5 text-gray-500 cursor-pointer" onClick={() => {
                        const latlng = new L.LatLng(parseFloat(result.lat), parseFloat(result.lon));
                        setPreviewLocation(latlng);
                        setPreviewName(result.display_name || 'Unnamed');
                        setResultsVisible(false); // Hide results list after preview
                      }} />
                    </li>
                  ))}
                </ul>
                {showPreviewButton && (
                  <button
                    onClick={handlePreviewSearchResults}
                    className="bg-green-500 text-white px-4 py-2 rounded-md mt-4"
                  >
                    Preview Search Results
                  </button>
                )}
              </>
            )}
          </div>
          <MapContainer center={mapCenter} zoom={zoomLevel} style={{ height: '400px', width: '100%' }} scrollWheelZoom={false}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapCenter position={previewLocation} />
            <MapEvents />
            {addresses.map((address) => (
              <Marker
                key={address.id}
                position={[address.map_location.lat, address.map_location.lng]}
                icon={customIcon}
                eventHandlers={{
                  click: () => {
                    onSelect(address);
                  },
                }}
              >
                <Tooltip>{address.name}</Tooltip>
              </Marker>
            ))}
            {selectedAddress && (
              <Marker
                position={[selectedAddress.map_location.lat, selectedAddress.map_location.lng]}
                icon={customIcon}
                zIndexOffset={1000} // Make sure selected marker is on top
              >
                <Popup>
                  <div>Selected: {selectedAddress.name}</div>
                </Popup>
              </Marker>
            )}
            {previewLocation && (
              <Marker
                position={previewLocation}
                icon={previewIcon}
                draggable
                title='safaria'
                eventHandlers={{
                  dragend: (event) => {
                    const latlng = (event.target as L.Marker).getLatLng();
                    setPreviewLocation(latlng);
                 // console.log('Preview marker dragged to:', latlng);
                  },
                }}
              >
                <Popup>
                  <div>{previewName}</div>
                </Popup>
              </Marker>
            )}
          </MapContainer>
          <div className="text-center mt-4">
           
            {previewLocation && (
              <button
                onClick={handleSaveAddress}
                className="bg-blue-500 text-white px-4 py-2 rounded-md ml-4"
              >
                Save as New Address
              </button>
            )}
          </div>
        </>
      )}
      <ModalAsk
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleModalSave}
        lat={previewLocation?.lat ?? defaultCenter[0]}
        lng={previewLocation?.lng ?? defaultCenter[1]}
        name={previewName}
      />
    </div>
  );
};

export default MapAddress;
