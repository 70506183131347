import React from "react";
import { useNavigate } from "react-router-dom";

export const SuccessPay = () => {
	const navigation = useNavigate();
	const handletoBooking = () => {
		navigation("/booking");
	};
	const handletoHome = () => {
		navigation("/");
	};
	return (
		<div>
			<div className="container min-h-[80vh] w-full ">
				<div className="flex flex-col items-center  ">
					<div className="relative mt-5 mb-5  h-[400px] w-[300px]">
			
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="217"
							height="254"
							viewBox="0 0 217 254"
							fill="none"
							className=" h-[80%] w-[80%]  m-auto"
						>
							<path
								d="M191.53 253.116H24.9428C11.3619 253.116 0.335938 242.09 0.335938 228.509V24.8533C0.335938 11.2723 11.3619 0.246338 24.9428 0.246338H191.53C205.111 0.246338 216.137 11.2723 216.137 24.8533V228.509C216.137 242.09 205.111 253.116 191.53 253.116Z"
								fill="#F5F5F5"
							/>
							<path
								d="M55.1523 185.171H167.22"
								stroke="#D4D4D4"
								strokeWidth="13.9"
								stroke-miterlimit="10"
								strokeLinecap="square"
							/>
							<path
								d="M73.0977 203.725H149.277"
								stroke="#D4D4D4"
								strokeWidth="13.9"
								stroke-miterlimit="10"
								strokeLinecap="square"
							/>
							<path
								d="M157.548 95.6669C157.548 121.433 136.656 142.306 110.909 142.306C85.1422 142.306 64.2695 121.433 64.2695 95.6669C64.2695 69.9003 85.1422 49.0276 110.909 49.0276C136.656 49.0276 157.548 69.9003 157.548 95.6669Z"
								stroke="#FFB229"
								strokeWidth="20"
								stroke-miterlimit="10"
								strokeLinecap="round"
							/>
							<path
								d="M90.4297 95.6669L106.232 111.449L165.528 49.0276"
								stroke="#05BD6B"
								strokeWidth="16"
								stroke-miterlimit="10"
								strokeLinecap="square"
							/>
						</svg>
					</div>
					<h2 className="mt-4 text-[24px] text-[#1E1E1E] text-[500] max-sm:text-[14px]">
						Successful Payment
					</h2>
					<span className="mt-4 text-[24px] text-[#69696A] text-[500] max-sm:text-[14px] ">
						Booking confirmed, thank you for your booking
					</span>
					<button
						onClick={handletoHome}
						className="mt-4 h-[54px] w-[300px] rounded-[9px] bg-[#0074C3] text-[20px] font-[500] text-white max-sm:h-[40px] max-sm:w-[200px]"
					>
						New booking
					</button>
					<button
						onClick={handletoBooking}
						className="mt-4 mb-4 h-[54px] w-[300px] rounded-[9px] border-[1px] border-[#0074C3] bg-white text-[20px] font-[500] text-[#0074C3] max-sm:h-[40px] max-sm:w-[200px]"
					>
						View a ticket
					</button>
				</div>
			</div>
		</div>
	);
};
