import React, { FC } from "react";
import moment, { Moment } from "moment";
import { CustomHeader } from "./SingleDate";
import { useTranslation } from "react-i18next";

type MoDateBickerProps = {
	date: Moment;
	setDate: (date: Moment) => void;
	defaultDateValue?: Moment;
	classNameContainer?: string;
};

export const MoDateBicker: FC<MoDateBickerProps> = ({
	date,
	setDate,
	defaultDateValue = null,
	classNameContainer,
}) => {
	const { t, i18n } = useTranslation();
	const currentLang = i18n.language;

	const today = new Date();

	const translateDate = (date: Moment, language: string) => {
		const formattedDate = date.format("ddd MM/DD").split(" ")[0];

		if (language === "ar") {
			// Translate to Arabic
			switch (formattedDate) {
				case "Sat":
					return "السبت";
				case "Sun":
					return "الأحد";
				case "Mon":
					return "الاثنين";
				case "Tue":
					return "الثلاثاء";
				case "Wed":
					return "الأربعاء";
				case "Thu":
					return "الخميس";
				case "Fri":
					return "الجمعة";
				default:
					return formattedDate;
			}
		}

		// Return the date in English for other languages
		return formattedDate;
	};

	const isCurrentDate = date.isSame(moment(), "day"); // Check if the selected date is the current date

	return (
		<div
			className={`container relative my-0 mx-1 h-[55px] w-[180px] rounded-md border bg-white px-2 text-gray-500 max-lg:w-[240px] max-md:rounded-3xl md:w-[160px]  lg:w-[220px] ${classNameContainer}`}
		>
			<div className="absolute left-0 top-0 z-30 flex h-[54px]  w-fit flex-row justify-center rounded-md text-center  text-gray-700  ltr:ml-2   ltr:flex-row-reverse  rtl:right-0  rtl:mr-2 rtl:flex-row-reverse">
				<h5 className=" max-med:ml+5  w-10px mt-5 text-xs font-bold text-gray-400 opacity-80  ltr:ml-2 ltr:text-left rtl:mr-2 rtl:text-right max-md:text-xs md:mx-4 lg:mt-5">
					{translateDate(date, currentLang)} {date.format("MM/DD")}
				</h5>
				<svg
					width="30"
					height="30"
					className="bg m-auto "
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4.75 1V3.25M15.25 1V3.25M1 16.75V5.5C1 4.90326 1.23705 4.33097 1.65901 3.90901C2.08097 3.48705 2.65326 3.25 3.25 3.25H16.75C17.3467 3.25 17.919 3.48705 18.341 3.90901C18.7629 4.33097 19 4.90326 19 5.5V16.75M1 16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75M1 16.75V9.25C1 8.65326 1.23705 8.08097 1.65901 7.65901C2.08097 7.23705 2.65326 7 3.25 7H16.75C17.3467 7 17.919 7.23705 18.341 7.65901C18.7629 8.08097 19 8.65326 19 9.25V16.75M10 10.75H10.008V10.758H10V10.75ZM10 13H10.008V13.008H10V13ZM10 15.25H10.008V15.258H10V15.25ZM7.75 13H7.758V13.008H7.75V13ZM7.75 15.25H7.758V15.258H7.75V15.25ZM5.5 13H5.508V13.008H5.5V13ZM5.5 15.25H5.508V15.258H5.5V15.25ZM12.25 10.75H12.258V10.758H12.25V10.75ZM12.25 13H12.258V13.008H12.25V13ZM12.25 15.25H12.258V15.258H12.25V15.25ZM14.5 10.75H14.508V10.758H14.5V10.75ZM14.5 13H14.508V13.008H14.5V13Z"
						stroke="#B9C4D5"
						
						strokeLinejoin="round"
					/>
				</svg>
			</div>
			<input
				type="date"
				value={date.format("YYYY-MM-DD")}
				onChange={event => {
					const selectedDate = moment(event.target.value, "YYYY-MM-DD");
					setDate(selectedDate);
					console.log(defaultDateValue);
				}}
				onFocus={e => e.target.showPicker()}
				min={moment(defaultDateValue).format("YYYY-MM-DD")}
				className="absolute left-0 top-0 z-40 h-full w-full rounded-md border-0 bg-transparent text-[0px] text-xs max-md:rounded-3xl"
			/>
		</div>
	);
};
