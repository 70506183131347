interface Seat {
    seat_id: string;
    seat_type_id: string;
  }
  
  /**
   * Checks if a seat number exists in the given array of seats.
   * @param seatsList - An array of seat objects.
   * @param seatNumber - The seat number to check for existence.
   *                     Can be either a number or a string.
   * @returns true if the seat number exists in the array of seat IDs, false otherwise.
   */
  export default function isSeatNumberExists(seatsList: Seat[], seatNumber: number | string): boolean {
    // Extract the seat IDs from the array of seat objects
    const seatIds = seatsList.map(seat => +seat.seat_id);
  
    // Convert seatNumber to a number if it is a string
    const numberToCheck = typeof seatNumber === 'string' ? parseInt(seatNumber, 10) : seatNumber;
  
    // Check if the numberToCheck exists in the seatIds array
    return seatIds.includes(numberToCheck);
  }