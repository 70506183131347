import React, { useState } from 'react';

interface ModalAskProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  lat: number;
  lng: number;
  name: string;
}

const ModalAsk: React.FC<ModalAskProps> = ({ isOpen, onClose, onSave, lat, lng, name }) => {
  const [locationName, setLocationName] = useState(name);

  const handleSubmit = () => {
    onSave(locationName);
 // console.log(`Location Name: ${locationName}, Latitude: ${lat}, Longitude: ${lng}`);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <h3 className="text-lg font-semibold mb-4">Name This Location</h3>
        <p className="mb-2">Latitude: {lat}</p>
        <p className="mb-4">Longitude: {lng}</p>
        <input
          type="text"
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
          placeholder="Enter location name"
          className="border border-gray-300 p-2 rounded-md mb-4 w-full"
        />
        <div className="flex justify-end">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded-md ml-2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAsk;
