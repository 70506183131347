import React from 'react';

interface Address {
  id: number;
  name: string;
  map_location: {
    lat: number;
    lng: number;
    address_name: string;
  };
}

interface AddressPreviewProps {
  addresses: Address[];
  onAddressClick: (address: Address) => void;
}

const AddressPreview: React.FC<AddressPreviewProps> = ({ addresses, onAddressClick }) => {
  return (
    <div className="w-full pt-5 px-4 mb-8 mx-auto">
      <div className="flex items-center  overflow-auto p-2 rounded-2xl shadow-lg">
        {addresses.map((address) => (
          <div
            key={address.id}
            className="relative inline-block h-8 min-w-[100px] rounded-md  border-2 border-white flex items-center justify-center scroll-smooth text-gray-100 text-xs font-medium cursor-pointer bg-cyan-600 overflow-hidden"
            onClick={() => onAddressClick(address)}
            title={address.name} // Tooltip to show full address name on hover
          >
            <span className="block whitespace-nowrap overflow-hidden text-ellipsis">
              {address.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddressPreview;
