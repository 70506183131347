import React, { FC, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AppContext } from "components/context/AppContext";
import useInput from "hooks/useInput";
import { useTranslation } from "react-i18next";
import { useValidatePhone } from "hooks/DataSend/useValidatedPhone";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useValidateOtp } from "hooks/DataSend/useValidateOtp";

export interface PageForgetPasswordProps {
  className?: string;
}

// Main functional component for the Forget Password Page
const PageForgetPassword: FC<PageForgetPasswordProps> = ({ className = "" }) => {
  const { t } = useTranslation(); // Translation hook for multi-language support
  const { token } = useContext(AppContext); // Get token from AppContext (could store API token here)
  const { mutate, isSuccess: isValidatedPhone } = useValidatePhone(); // Hook for validating phone number
  const { mutate: mutateOtp, data: otpData, isSuccess: isValidatedOtp } = useValidateOtp(); // Hook for OTP validation
  const [otp, setOtp] = useState<string>(""); // State for OTP input

  // Phone input validation hook
  const {
    value: phone,
    isValid: PhoneIsValid,
    hasError: phoneHasError,
    inputBlurHandler: phoneBlur,
    valueChangeHandler: phoneChange,
  } = useInput((value: any) => value.length <= 12);

  // Function to handle phone number submission for verification
  const handleSubmitPhone = (event: any) => {
    event.preventDefault();
    if (!PhoneIsValid) return;

    const officialPhone = phone?.substring(1); // Removes the first character if needed (e.g., leading zero)
    const data = new FormData();
    data.append("mobile", officialPhone);
    data.append("phonecode", "20");

    mutate(data); // Calls the phone validation mutation
    localStorage.setItem("phone", `${phone}`); // Stores the phone number in localStorage
  };

  // Function to handle OTP submission for final verification
  const handleSubmitOtp = () => {
    if (!PhoneIsValid) return;

    const officialPhone = phone?.substring(1);
    const data = new FormData();
    data.append("mobile", officialPhone);
    data.append("phonecode", "20");
    data.append("code", otp);

    // Call the OTP validation mutation
    mutateOtp(data);
  };
	const navigate = useNavigate();


  // Console log response after successful OTP validation
  React.useEffect(() => {
    if (isValidatedOtp) {

      console.log("OTP Verification Success:", otpData); // Logs OTP response data to console
	  localStorage.setItem("token", otpData?.data?.data?.api_token);
				localStorage.setItem("email", otpData?.data?.data?.email);
				localStorage.setItem("phone", otpData?.data?.data?.mobile);
				localStorage.setItem("name", otpData?.data?.data?.name);
				const path = window.localStorage.getItem("page_path_name")
				navigate(`${path}`)
      if (otpData?.api_token) {
        console.log("API Token:", otpData.api_token); // Logs the API token if available
      }
    }
  }, [isValidatedOtp, otpData]);

  return (
    <div className={`nc-PageForgetPassword ${className}`} data-nc-id="PageForgetPassword">
      <Helmet>
        <title>{t("forgetPassword")}</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center justify-center text-3xl font-semibold leading-[115%] text-neutral-900 dark:text-neutral-100 md:text-5xl md:leading-[115%]">
          {t("resetPassword")}
        </h2>
        <div className="mx-auto max-w-md space-y-6">
          {/* Form for phone number input */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmitPhone}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">{t("phoneNumber")}</span>
              <Input
                type="text"
                placeholder="0105478...."
                className="mt-1"
                value={phone}
                onChange={phoneChange}
                onBlur={phoneBlur}
                autoFocus
              />
              {phoneHasError && <p className="mt-1 text-red-900">{t("enterValidPhone")}</p>}
            </label>

            {/* Conditional rendering for OTP input after phone verification */}
            {isValidatedPhone && !isValidatedOtp && (
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">{t("otp")}</span>
                </label>
                <div className="flex w-full justify-center">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    separator={<span>&nbsp;</span>}
                    inputStyle={{
                      width: "3.5rem",
                      height: "3.5rem",
                      border: "1px solid #4348ca",
                      borderRadius: "4px",
                      fontSize: "1.5rem",
                      textAlign: "center",
                    }}
                  />
                </div>
                <ButtonPrimary onClick={handleSubmitOtp}>
                  {t("continueResetPassword")}
                </ButtonPrimary>
              </div>
            )}
            {!isValidatedPhone && <ButtonPrimary type="submit">{t("continue")}</ButtonPrimary>}
          </form>

          {/* Link to sign-up page for new users */}
          {!token && (
            <div className="relative text-center mt-4">
              <span className="text-neutral-700 dark:text-neutral-300">
                {t("newUser")}?{" "}
                <Link to="/signup">
                  <span className="text-primary-600">{t("createAnAccount")}</span>
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageForgetPassword;
