import React, { FC } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import heroRightImage from "images/hero-right-car.png";
import i18n from "i18n";

export interface ListingCarMapPageProps {
	className?: string;
}
const currentLanguage:string = i18n.language;

const ListingCarMapPage: FC<ListingCarMapPageProps> = ({ className = "" }) => {
	return (
		<div
			className={`nc-ListingCarMapPage relative ${className}`}
			data-nc-id="ListingCarMapPage"
		>
			{currentLanguage === 'ar' ?
				<title>احجز أفضل عروض الطیران والمواصلات في مصر - سفریة</title>
				:
				<title>TBook flight and transportation deals in Egypt - Safaria</title>

			 }
			<BgGlassmorphism />

			{/* SECTION HERO */}
			<div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
				{/* <SectionHeroArchivePage
					rightImage={heroRightImage}
					currentPage="Cars"
					currentTab="Cars"
					trips={[]}
					listingType={
						<>
							<i className="las la-car text-2xl"></i>
							<span className="ml-2.5">1512 cars</span>
						</>
					}
				/> */}
			</div>

			{/* SECTION */}
			<div className="container pb-24 lg:pb-28 xl:max-w-none xl:pr-0 2xl:pl-10">
				<SectionGridHasMap />
			</div>

			<div className="container overflow-hidden">
				{/* SECTION 1 */}
		
				{/* SECTION */}
				{/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}

				{/* SECTION */}
				{/* <div className="relative mb-24 py-16 lg:mb-28">
					<BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
					<SectionGridAuthorBox />
				</div> */}
			</div>
		</div>
	);
};

export default ListingCarMapPage;
