import React, { useEffect, useState } from "react";
import classes from "./Booking.module.css";
import Logo from "../../images/WebusLogo.png";
import ProfileButtom from "components/ProfileButtom/ProfileButtom";
import Profile from "images/Profile";
import ShowIcon from "images/logos/ShowIcon";
import CurrentCard from "components/CurrentCard/CurrentCard";
import PrevCard from "components/PrevCard/PrevCard";
import PendCard from "components/PendCard/PendCard";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { format, addHours, isAfter } from "date-fns";

import {
	getAddressList,
	listBus,
	listMaritime,
	listPrivates,
	searchTripsMaritime,
} from "api";
import { showApiErrorMessages } from "utils";
import { Link, useNavigate } from "react-router-dom";
import EmptyState from "components/EmptyState/EmptyState";
import { forEach } from "lodash";
import { useTranslation } from "react-i18next";
import { Ticket } from "utils/types";
import { MessageDialog } from "components/Modal/modal";
import axios from "axios";
import i18n from "i18n";
function compareDate(dateStr: string): boolean {
	const currentDate = new Date();
	const targetDate = new Date(dateStr);

	// Add 4 hours to the current date
	const fourHoursFromNow = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000);

	return targetDate > fourHoursFromNow;
}
const BookingCard = () => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const [privates, setPrivates] = useState<any>([]);
	const [addressList, setAddressList] = useState<any>([]);
	const [bus, setBus] = useState<Ticket[]>([]);
	const [fbus, setFbus] = useState<any>([]);
	const [maritimes, setMritimes] = useState<any>([]);
	const [allTrips, setAllTrips] = useState<Ticket[]>([]);
	const [nav, setNav] = useState<any>("All");
	let newBus: any[] = [];
	function isOrderDateTimeValid(order: any): boolean {
		const { date_time } = order;

		const currentDate = new Date();
		const orderDateTime = new Date(date_time);

		const maxTimeDifferenceHours = 24;
		const maxDateTime = addHours(currentDate, maxTimeDifferenceHours);

		return isAfter(orderDateTime, maxDateTime);
	}

	const { data: busData, isLoading: busIsLoading } = useQuery(
		["getBusData"],
		() => {
			return listBus();
		},
		{
			keepPreviousData: true,
			onSuccess: response => {
				if (response?.data?.data.length) {
					setBus([...response?.data?.data]);
					// console.log(bus, response?.data?.data, "orders");
				}
			},
			onError: (errors: any) => {
				if (Object.keys(errors.response.data.errors)?.length) {
					showApiErrorMessages(errors.response.data.errors);
				} else {
					toast.error(errors.response.data.message);
				}
				if (errors.response.status === 401) {
					navigate("/login");
				}
			},
		},
	);

	function isOrderRunning(order: any): boolean {
		const { station_from, station_to } = order;

		const currentDate = new Date();
		const arrivalFrom = new Date(station_from.arrival_at);
		const arrivalTo = new Date(station_to.arrival_at);

		return currentDate >= arrivalFrom && currentDate <= arrivalTo;
	}
	async function pay(order: any) {
		const token = localStorage.getItem("token");
		const paymentUrl = order?.payment_url;

		if (paymentUrl) {
			try {
				const response = await axios.post(
					paymentUrl,
					{},
					{
						headers: {
							"Content-Type": "multipart/form-data",
							"Accept-Language": i18n?.language,
							Authorization: "Bearer " + token,
						},
					},
				);
				const { url } = response?.data?.data;
				window.location.href = url;
			} catch (error: any) {
				// console.log(error.message);
			}
		}
	}

	async function cancel(order: Ticket) {
		const cancelUrl = order?.cancel_url;
		const token = localStorage.getItem("token");

		if (cancelUrl) {
			try {
				// Send cancel request
				const response = await axios.post(
					cancelUrl,
					{},
					{
						headers: {
							"Content-Type": "multipart/form-data",
							"Accept-Language": i18n?.language,
							Authorization: "Bearer " + token,
						},
					},
				);

				// Remove order from bus list if cancel request is successful
				if (response.status === 200) {
					setBus(prevBus => prevBus.filter(item => item !== order));
				}
			} catch (error: any) {
				// console.log(error.message);
			}
		}
	}
	const ContainerNull = () => {
		return (
			<div className=" min-h-[400px] ">
				<svg
					width="450"
					height="300"
					viewBox="0 0 450 300"
					className="m-auto min-h-[300px] min-w-full max-sm:min-h-[200] max-sm:w-[300px] "
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clipPath="url(#clip0_792_18363)">
						<path
							d="M184.462 37.069V70.9225M274.738 37.069V70.9225M133.682 117.074H325.519M331.16 110.418V206.336C331.16 240.19 314.234 262.759 274.738 262.759H184.462C144.966 262.759 128.039 240.19 128.039 206.336V110.418C128.039 76.5645 144.966 53.9955 184.462 53.9955H274.738C314.234 53.9955 331.16 76.5645 331.16 110.418Z"
							stroke="#E1E4E5"
							strokeWidth="12"
							stroke-miterlimit="10"
							
							strokeLinejoin="round"
						/>
						<path
							d="M271.294 169.1H271.396M271.294 202.953H271.396M229.551 169.1H229.652M229.551 202.953H229.652M187.785 169.1H187.886M187.785 202.953H187.886"
							stroke="#E1E4E5"
							strokeWidth="24"
							
							strokeLinejoin="round"
						/>
						<path
							d="M119.507 72.328L90.9575 83.0645C84.378 85.533 79 93.341 79 100.403V143.06C79 149.835 83.463 158.733 88.898 162.81L113.5 181.239C121.568 187.325 134.841 187.325 142.908 181.239L167.51 162.81C172.945 158.733 177.408 149.835 177.408 143.06V100.403C177.408 93.341 172.03 85.533 165.451 83.0645L136.9 72.328C132.037 70.5485 124.257 70.5485 119.507 72.328Z"
							fill="url(#paint0_linear_792_18363)"
						/>
						<path
							d="M128.131 150.425C114.24 150.425 102.98 139.164 102.98 125.274C102.98 111.384 114.24 100.124 128.131 100.124C142.021 100.124 153.281 111.384 153.281 125.274C153.281 139.164 142.021 150.425 128.131 150.425Z"
							stroke="white"
							strokeWidth="5"
							stroke-miterlimit="10"
							
							strokeLinejoin="round"
						/>
						<path
							d="M126.559 117.415V123.262C126.559 125.463 127.691 127.538 129.64 128.67L134.418 131.562"
							stroke="white"
							strokeWidth="5"
							stroke-miterlimit="10"
							
							strokeLinejoin="round"
						/>
						<path
							d="M111.318 60.189C119.298 64.1475 129.89 59.0575 129.89 59.0575C129.89 59.0575 127.536 47.552 119.552 43.599C111.572 39.6405 100.984 44.7245 100.984 44.7245C100.984 44.7245 103.338 56.2305 111.318 60.189Z"
							fill="url(#paint1_linear_792_18363)"
						/>
						<path
							d="M104.941 224.83C108.121 224.83 110.699 227.408 110.699 230.587C110.699 233.767 108.121 236.345 104.941 236.345C101.761 236.345 99.1836 233.767 99.1836 230.587C99.1836 227.408 101.761 224.83 104.941 224.83Z"
							fill="url(#paint2_linear_792_18363)"
						/>
						<path
							d="M334.175 36.888C339.068 36.888 343.034 40.8543 343.034 45.747C343.034 50.6396 339.068 54.606 334.175 54.606C329.283 54.606 325.316 50.6396 325.316 45.747C325.316 40.8543 329.283 36.888 334.175 36.888Z"
							fill="url(#paint3_linear_792_18363)"
						/>
						<path
							d="M359.212 124.278C362.39 124.278 364.967 121.701 364.967 118.523C364.967 115.345 362.39 112.768 359.212 112.768C356.034 112.768 353.457 115.345 353.457 118.523C353.457 121.701 356.034 124.278 359.212 124.278Z"
							fill="url(#paint4_linear_792_18363)"
						/>
						<path
							d="M363.099 182.806C367.591 182.806 371.233 179.164 371.233 174.672C371.233 170.18 367.591 166.538 363.099 166.538C358.607 166.538 354.965 170.18 354.965 174.672C354.965 179.164 358.607 182.806 363.099 182.806Z"
							fill="#E1E4E5"
						/>
						<path
							d="M347.94 254.498C351.83 254.498 354.985 251.344 354.985 247.453C354.985 243.563 351.83 240.408 347.94 240.408C344.049 240.408 340.895 243.563 340.895 247.453C340.895 251.344 344.049 254.498 347.94 254.498Z"
							fill="#E1E4E5"
						/>
						<path
							d="M98.9317 197.191C101.291 197.191 103.203 195.617 103.203 193.674C103.203 191.731 101.291 190.156 98.9317 190.156C96.5726 190.156 94.6602 191.731 94.6602 193.674C94.6602 195.617 96.5726 197.191 98.9317 197.191Z"
							fill="#E1E4E5"
						/>
						<path
							d="M118.278 267.545C118.278 270.32 120.528 272.57 123.303 272.57C126.078 272.57 128.328 270.32 128.328 267.545C128.328 264.769 126.078 262.52 123.303 262.52C120.528 262.52 118.278 264.769 118.278 267.545Z"
							fill="#E1E4E5"
						/>
					</g>
					<defs>
						<linearGradient
							id="paint0_linear_792_18363"
							x1="79"
							y1="128.398"
							x2="177.408"
							y2="128.398"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#D4CFBF" />
							<stop offset="1" stop-color="#D5CC9F" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_792_18363"
							x1="141.963"
							y1="72.5365"
							x2="76.1429"
							y2="20.473"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="white" />
							<stop offset="1" stop-color="#EEEEEE" />
						</linearGradient>
						<linearGradient
							id="paint2_linear_792_18363"
							x1="99.1836"
							y1="230.587"
							x2="110.699"
							y2="230.587"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#D4CFBF" />
							<stop offset="1" stop-color="#D5CC9F" />
						</linearGradient>
						<linearGradient
							id="paint3_linear_792_18363"
							x1="325.316"
							y1="45.747"
							x2="343.034"
							y2="45.747"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#D4CFBF" />
							<stop offset="1" stop-color="#D5CC9F" />
						</linearGradient>
						<linearGradient
							id="paint4_linear_792_18363"
							x1="353.457"
							y1="118.523"
							x2="364.967"
							y2="118.523"
							gradientUnits="userSpaceOnUse"
						>
							<stop stop-color="#D4CFBF" />
							<stop offset="1" stop-color="#D5CC9F" />
						</linearGradient>
						<clipPath id="clip0_792_18363">
							<rect width="450" height="300" fill="white" />
						</clipPath>
					</defs>
				</svg>
				<div className="text-center">You currently have no bookings.</div>
			</div>
		);
	};
	function isOrderPaid(order: any): boolean {
		return (
			order.status_code === "success" &&
			order.payment_data.status_code === "success"
		);
	}
	function canBeCanceled(order: any): boolean {
		const currentDate = new Date();
		const orderDate = new Date(order?.station_from?.arrival_at);

		// Check if the payment status is "success"
		if (
			(order.payment_data.status_code === "success" ||
				order.payment_data.status_code === "pending") &&
			(order.status_code === "pending" || order.status_code === "success") &&
			order.can_be_cancel
		) {
			// Calculate the time difference in hours
			const timeDifferenceHours =
				Math.abs(orderDate.getTime() - currentDate.getTime()) /
				(1000 * 60 * 60);

			// Check if the order is in the future and more than 24 hours away
			if (orderDate > currentDate && timeDifferenceHours > 24) {
				return true;
			}
		}

		return false;
	}
	function isOrderInFutAndPaid(order: any): boolean {
		const currentDate = new Date();
		const orderDate = new Date(order.date);

		// Check if the order date is in the past
		if (orderDate > currentDate) {
			// Check if the payment status is "success"
			return order.payment_data.status_code === "success";
		}

		return false;
	}
	const card_order = (type: string, order: any) => {
		return (
			<div
				className={`type rounded-2xl border bg-white shadow ${
					isOrderInPastAndPaid(order)
						? " border-sky-600 "
						:  canBePaid(order)
						? "border-2 border-dashed border-red-400"
						: "border-2 border-dashed border-gray-400"
				} p-4`}
			>
				{/* from three containers  */}
				<div className="flex flex-row justify-between ">
					<div className="h-10 w-[83.33px] ">
						<img
							className="h-10 w-[83.33px]  object-contain"
							src={order?.company_data?.avatar}
							alt=""
						/>
					</div>
					<div className="flex flex-row ">
						<div className="font-Cairo mx-2 text-xl font-normal leading-7 text-neutral-500">
							{order?.tickets?.map((ticket: any) => (
								<span key={ticket?.id} className="flex flex-row rtl:flex-row-reverse rtl:ml-4 justify-center align-middle gap-1 ">
									<svg width="18" height="20" viewBox="0 0 18 22" className="mt-1" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.749 5C12.749 5.99456 12.3539 6.94839 11.6507 7.65165C10.9474 8.35491 9.99356 8.75 8.999 8.75C8.00444 8.75 7.05061 8.35491 6.34735 7.65165C5.64409 6.94839 5.249 5.99456 5.249 5C5.249 4.00544 5.64409 3.05161 6.34735 2.34835C7.05061 1.64509 8.00444 1.25 8.999 1.25C9.99356 1.25 10.9474 1.64509 11.6507 2.34835C12.3539 3.05161 12.749 4.00544 12.749 5ZM1.5 19.118C1.53213 17.1504 2.33634 15.2742 3.73918 13.894C5.14202 12.5139 7.03109 11.7405 8.999 11.7405C10.9669 11.7405 12.856 12.5139 14.2588 13.894C15.6617 15.2742 16.4659 17.1504 16.498 19.118C14.1454 20.1968 11.5871 20.7535 8.999 20.75C6.323 20.75 3.783 20.166 1.5 19.118Z" stroke="#69696A" strokeWidth="1.5"  strokeLinejoin="round"/>
</svg>

									{ticket?.seat_number}</span>
							))}
						</div>
						<div className="flex flex-row font-['Cairo'] text-xl font-medium leading-[30px] text-sky-600">
							<>{order?.total?.slice(0, 3)}</>{" "}
							<>{t(order?.total?.slice(4, order?.total?.length))}</>
						</div>
					</div>
				</div>
				{/* seconed container for trip */}
				<div className="mt-[16px] flex min-h-[57px]  flex-row justify-between border-t  border-slate-200 p-4   ">
					<div className="  flex flex-row gap-1 ">
						<svg
							width="6"
							height="57"
							viewBox="0 0 6 57"
							className=" h-[57px]"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							{" "}
							<path
								d="M3 0.833333C1.52724 0.833333 0.333333 2.02724 0.333333 3.5C0.333333 4.97276 1.52724 6.16667 3 6.16667C4.47276 6.16667 5.66667 4.97276 5.66667 3.5C5.66667 2.02724 4.47276 0.833333 3 0.833333ZM3 50.8333C1.52724 50.8333 0.333331 52.0272 0.333331 53.5C0.333331 54.9728 1.52724 56.1667 3 56.1667C4.47276 56.1667 5.66666 54.9728 5.66666 53.5C5.66666 52.0272 4.47276 50.8333 3 50.8333ZM2.5 3.5L2.5 53.5L3.5 53.5L3.5 3.5L2.5 3.5Z"
								fill="#69696A"
							/>
						</svg>
						
						<div className="flex h-[100%] min-h-[57px] flex-col justify-between  ">
							<div className=" font-['Cairo'] text-base font-normal leading-normal text-stone-900">
								{order?.station_from?.city_name} ( {order?.station_from?.name})
							</div>
							<div className=" font-['Cairo'] text-base font-normal leading-normal text-stone-900">
								{order?.station_to?.city_name} ( {order?.station_to?.name})
							</div>
						</div>
					</div>
					<div>
						<div className="flex h-[100%] min-h-[57px] flex-col justify-between  ">
							<div className=" font-['Cairo'] text-base font-normal leading-normal text-stone-900">
								{format(new Date(order?.station_from?.arrival_at), "hh:mm a")}{" "}
							</div>
							<div className=" font-['Cairo'] text-base font-normal leading-normal text-stone-900">
								{format(new Date(order?.station_to?.arrival_at), "hh:mm a")}{" "}
							</div>
						</div>
					</div>
				</div>

				{/* tired is for action in card  */}
				<div className="flex flex-row justify-between ">
					{(isOrderInFutAndPaid(order) || isOrderRunning(order)) &&
						isOrderPaid(order) && (
							<Link
								to={`/trips/trip/${order?.id}`}
								className="inline-flex h-12 w-[110px] items-center justify-center gap-2 rounded-[9px] border border-sky-600 bg-white text-lg font-medium capitalize text-sky-600 shadow max-sm:w-[60%] max-sm:gap-0 max-sm:px-1 max-sm:text-xs"
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M2.03613 12.322C1.96712 12.1146 1.96712 11.8904 2.03613 11.683C3.42313 7.51 7.36013 4.5 12.0001 4.5C16.6381 4.5 20.5731 7.507 21.9631 11.678C22.0331 11.885 22.0331 12.109 21.9631 12.317C20.5771 16.49 16.6401 19.5 12.0001 19.5C7.36213 19.5 3.42613 16.493 2.03613 12.322Z"
										stroke="#2E74BD"
										strokeWidth="1.5"
										
										strokeLinejoin="round"
									/>
									<path
										d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z"
										stroke="#2E74BD"
										strokeWidth="1.5"
										
										strokeLinejoin="round"
									/>
								</svg>
								ticket
							</Link>
						)}
					{isOrderInPastAndPaid(order) && <MessageDialog ticket={order?.id} />}
					<div className=" flex flex-row gap-2 ">
						{canBePaid(order) && (
							<>
								<button
									className="inline-flex h-12 w-[110px] items-center justify-center gap-2 rounded-[9px] bg-sky-600 p-4 text-white shadow max-sm:w-[60%] max-sm:text-xs "
									onClick={() => pay(order)}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.25 18.75C7.58561 18.7457 12.898 19.4522 18.047 20.851C18.774 21.049 19.5 20.509 19.5 19.755V18.75M3.75 4.5V5.25C3.75 5.44891 3.67098 5.63968 3.53033 5.78033C3.38968 5.92098 3.19891 6 3 6H2.25M2.25 6V5.625C2.25 5.004 2.754 4.5 3.375 4.5H20.25M2.25 6V15M20.25 4.5V5.25C20.25 5.664 20.586 6 21 6H21.75M20.25 4.5H20.625C21.246 4.5 21.75 5.004 21.75 5.625V15.375C21.75 15.996 21.246 16.5 20.625 16.5H20.25M2.25 15V15.375C2.25 15.6734 2.36853 15.9595 2.5795 16.1705C2.79048 16.3815 3.07663 16.5 3.375 16.5H3.75M2.25 15H3C3.19891 15 3.38968 15.079 3.53033 15.2197C3.67098 15.3603 3.75 15.5511 3.75 15.75V16.5M20.25 16.5V15.75C20.25 15.5511 20.329 15.3603 20.4697 15.2197C20.6103 15.079 20.8011 15 21 15H21.75M20.25 16.5H3.75M15 10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5C9 9.70435 9.31607 8.94129 9.87868 8.37868C10.4413 7.81607 11.2044 7.5 12 7.5C12.7956 7.5 13.5587 7.81607 14.1213 8.37868C14.6839 8.94129 15 9.70435 15 10.5ZM18 10.5H18.008V10.508H18V10.5ZM6 10.5H6.008V10.508H6V10.5Z"
											stroke="white"
											strokeWidth="1.5"
											
											strokeLinejoin="round"
										/>
									</svg>

									{t("Pay")}
								</button>
							</>
						)}{" "}
					</div>
					{canBeCanceled(order) && (
						<button
							className="inline-flex h-12 w-[110px]  items-center justify-center  gap-2 rounded-[9px] bg-red-600 p-4 text-white shadow max-sm:w-[80px] max-sm:text-lg"
							onClick={() => cancel(order)}
						>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
									stroke="white"
									strokeWidth="1.5"
									
									strokeLinejoin="round"
								/>
							</svg>

							{t("cancel")}
						</button>
					)}
				</div>
			</div>
		);
	};

	function isOrderInPastAndPaid(order: any): boolean {
		const currentDate = new Date();
		const orderDate = new Date(order.date);

		// Check if the order date is in the past
		if (orderDate < currentDate) {
			// Check if the payment status is "success"
			return order.payment_data.status_code === "success";
		}

		return false;
	}
	function canBePaid(order: any): boolean {
		if (
			order?.status_code?.toLowerCase() === "pending" &&
			order?.payment_data?.status_code.toLowerCase() === "pending"
		) {
			return true;
		}
		return false;
	}
	const renderContent = () => {
		switch (nav) {
			case "All":
				return (
					<div className="flex flex-col gap-8">
						{!bus?.length ? (
							ContainerNull()
						) : (
							<>
								{bus.map(bus_new => {
									return <>{card_order("all", bus_new)}</>;
								})}
							</>
						)}
					</div>
				);
			case "Current":
				let isCurrCentData = false;
				return (
					<div className="flex flex-col gap-8">
						{bus.map(bus_new => {
							if (isOrderPaid(bus_new) && isOrderRunning(bus_new)) {
								isCurrCentData = true;
								return <>{card_order("Current", bus_new)}</>;
							}
						})}
						{!isCurrCentData && ContainerNull()}
					</div>
				);
			case "Pending":
				let isPendingData = false;

				return (
					<div className="flex flex-col gap-8">
						{bus.map(bus_new => {
							if (
								!isOrderPaid(bus_new) &&
								!isOrderRunning(bus_new) &&
								canBePaid(bus_new)
							) {
								isPendingData = true;
								return <>{card_order("Pending", bus_new)}</>;
							}
						})}
						{!isPendingData && ContainerNull()}
					</div>
				);
			case "Previous":
				let isPreviousData = false;

				return (
					<div className="flex flex-col gap-8">
						{bus.map(bus_new => {
							if (isOrderInPastAndPaid(bus_new)) {
								isPreviousData = true;
								return <>{card_order("Previous", bus_new)}</>;
							}
						})}
						{!isPreviousData && ContainerNull()}
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<div className="container mx-auto rounded-2xl bg-white shadow  xl:mt-10 2xl:mt-32">
			<div className="mb-4 flex justify-center gap-2.5">
				<button
					className={`mr-2 px-4 py-2  ${
						nav === "All"
							? " border-b border-sky-600 text-sky-600 "
							: "border-b border-slate-200 text-neutral-500 "
					}`}
					onClick={() => setNav("All")}
				>
					{t("All bookings")}
				</button>
				<button
					className={`mr-2 w-[136px] px-4  py-2 ${
						nav === "Current"
							? " border-b border-sky-600 text-sky-600 "
							: "border-b border-slate-200 text-neutral-500 "
					}`}
					onClick={() => setNav("Current")}
				>
					{t("Current bookings")}
				</button>
				<button
					className={` p-2.5  ${
						nav === "Pending"
							? " border-b border-sky-600 text-sky-600 "
							: "border-b border-slate-200 text-neutral-500 "
					}`}
					onClick={() => setNav("Pending")}
				>
					{t("Pending bookings")}
				</button>
				<button
					className={`px-4 py-2  ${
						nav === "Previous"
							? " border-b border-sky-600 text-sky-600 "
							: "border-b border-slate-200 text-neutral-500 "
					}`}
					onClick={() => setNav("Previous")}
				>
					{t("Previous bookings")}
				</button>
			</div>
			<div className="p-4">{renderContent()}</div>
		</div>
	);
};
export default BookingCard;
