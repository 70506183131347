/**
 * Adds hours to a given date-time string.
 * @param dateTime - The date-time string to which hours will be added in 'YYYY-MM-DD HH:mm' format.
 * @param hoursToAdd - The number of hours to add to the date-time.
 * @returns The updated date-time string with the added hours in 'YYYY-MM-DD HH:mm' format.
 */
export const addHoursToDateTime = (dateTime: string, hoursToAdd: number): string => {
    console.log("Input dateTime:", dateTime);
    console.log("Hours to add:", hoursToAdd);
  
    // Parse the input date-time string into a Date object
    const date = new Date(dateTime);
  
    // Check for invalid Date object
    if (isNaN(date.getTime())) {
      console.error("Invalid Date object.");
      return "Invalid Date";
    }
  
    // Add the specified number of hours to the Date object
    date.setHours(date.getHours() + hoursToAdd);
  
    // Format the updated Date object as a string in 'YYYY-MM-DD HH:mm' format
    const formattedDate = [
      date.getFullYear(),
      String(date.getMonth() + 1).padStart(2, '0'),
      String(date.getDate()).padStart(2, '0')
    ].join('-') + ' ' + [
      String(date.getHours()).padStart(2, '0'),
      String(date.getMinutes()).padStart(2, '0')
    ].join(':');
  
    console.log("Formatted date-time after adding hours:", formattedDate);
  
    return formattedDate;
  };