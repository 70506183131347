import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bus from "images/bluebus.jpeg";
import tv from "images/outline.png";
import conditioning from "images/air-conditioner 1.png";
import we_bus_logo from "images/Rectangle 124.png";
import blue_bus_logo from "images/image 4.png";
import miniBus from "images/bus1.png";
import moment, { Moment } from "moment";
import { t } from "i18next";
import { toast } from "react-toastify";
import { TravelData } from "utils/types";

export interface FlightCardProps {
	refactoredData?: any;
}

const FlightCard: FC<FlightCardProps> = ({ refactoredData }) => {
	interface Props {
		item: {
			gateway_id: string;
			company_logo: string;
		};
	}

	function removeDuplicates(travelData: any) {
		const uniqueData = [];
		const keySet: string[] = [];

		for (const item of travelData) {
			const key = `${item?.trip_url}`;

			if (!keySet.includes(key)) {
				uniqueData.push(item);
				keySet.push(key);
			}
		}

		return uniqueData;
	}

	const calculateDuration = (
		start_date: string,
		end_date: string,
	): any | string => {
		const formats = ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"];
		let duration: moment.Duration | null = null;

		for (const date_format of formats) {
			const start_datetime = moment(start_date, date_format);
			const end_datetime = moment(end_date, date_format);
			if (start_datetime.isValid() && end_datetime.isValid()) {
				duration = moment.duration(end_datetime.diff(start_datetime));
				break;
			}
		}

		if (duration === null) {
			throw new Error("Invalid date format for start_date or end_date");
		}

		const hours = Math.floor(duration.asHours());
		const minutes = Math.floor(duration.asMinutes() % 60);

		return ` ${t("H")} ${hours}   ${t("M")} ${minutes}`;
	};

	refactoredData = removeDuplicates(refactoredData);

	const navigate = useNavigate();
	const busCardContainer = (refactoredData: any) => {
		return refactoredData?.map((item: TravelData) => {
			const bus_image = item?.company_data?.bus_image
				? item.company_data.bus_image
				: item.gateway_id.includes("WEBUS")
				? miniBus
				: item.gateway_id.includes("BlueBus")
				? bus
				: item.gateway_id.includes("Bellman")
				? item.company_logo
				: item.gateway_id.includes("OnTime")
				? item.company_logo
				: null;
			return (
				<div
					key={item.id}
					className="lg:h-100 md:h-100 m-0 flex h-[80%] w-[100%] mb-3 
                  flex-col
                  bg-white
                  max-sm:h-fit 
                  sm:h-fit 
                  
		  "
					style={{
						borderRadius: "16px",
						padding: "0px 16px 16px",
						//   boxShadow:"0 4px 4px 0 rgba(0, 0, 0, 0.25)"
					}}
				>
					<div
						className="W-100 flex h-[70px] flex-row justify-between pt-4 rtl:ml-2 lg:mr-2  "
						style={{
							borderBottom: "1px solid var(--boareder, #E8ECF2)",
							alignItems: "center",
						}}
					>
						<div>
							<div className="w-24 flex-shrink-0 lg:w-36 ">
								<img
									src={item?.company_data?.avatar}
									className="h-[40px] w-[75px] flex-shrink-0 lg:w-[70px] "
									alt=""
								/>
							</div>
						</div>
						<div>
							<div>
								<div className="ml-0 flex w-fit flex-shrink-0 flex-row justify-between lg:w-fit">
									<div className="mr-2">
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12 6V12H16.5M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
												stroke="#69696A"
												
												
												strokeLinejoin="round"
											/>
										</svg>
									</div>
									<div className="rel:rotate-[]">
										{" "}
										{calculateDuration(item?.travel_at, item?.arrival_at)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* seconed container for body  it is from 2 containers*/}

					<div
						className="w-100 flex h-[148px] flex-row justify-between p-[16px] align-middle max-sm:h-fit  
                  max-sm:flex-row sm:h-[200px]
                  sm:flex-row md:h-[148px]
                  md:flex-row lg:mr-0
                  lg:h-[148px] lg:flex-row
				"
						style={{ borderBottom: "1px solid #E8ECF2" }}
					>
						{/* first contain left */}
						<div className="flex  flex-row space-y-6 max-sm:h-[175px] max-sm:w-[200px] sm:flex-row sm:items-center sm:space-y-0 ">
							<div className="me-[-50%] position-relative m-auto flex h-[80%] flex-col justify-center max-sm:h-[60%] sm:h-[70%] ">
								<div className="h-[5px] w-[5px] rounded-full bg-[#69696A]"></div>
								<div className="m-auto h-[95%] w-[1px] rounded-full bg-[#69696A]  "></div>
								<div className="mt-[-50%] h-[5px] w-[5px] rounded-full bg-[#69696A]"></div>
							</div>

							<div className="my-[16px]  ml-[17.2px] w-[428px] rtl:mr-3">
								<div className="mb-[15px] max-sm:w-[200px]">
									<h4
										className=" mb-[8px] text-[16px]  font-[600]"
										style={{ color: "#1E1E1E", lineHeight: "150.7%" }}
									>
										{item?.city_from_name} ({item?.travel_from})
									</h4>
									<h4
										className=" text-[16px] font-[400]"
										style={{ color: "#69696A", lineHeight: "100.7%" }}
									>
										{item?.travel_at}
									</h4>
								</div>
								<div>
									<h4
										className=" mb-[8px] text-[16px] font-[600]"
										style={{ color: "#1E1E1E", lineHeight: "150.7%" }}
									>
										{item?.city_to_name} ({item?.travel_to})
									</h4>
									<h4
										className=" text-[16px] font-[400]"
										style={{ color: "#69696A", lineHeight: "100.7%" }}
									>
										{item?.arrival_at}
									</h4>
								</div>
							</div>
						</div>
						{/* seconde contain left */}

						<div className="m-auto  flex flex-col space-y-6  bg-black rtl:ml-0 max-sm:h-fit sm:flex-row sm:items-center sm:space-y-0 lg:mr-0">
							{/* LOGO IMG */}

							<div className="m-auto w-[160x] flex-shrink-0  max-sm:w-[90px] sm:w-[120px] md:translate-x-[0px]    ">
								{bus_image && (
									<img src={bus_image} className=" object-contain" alt="" />
								)}
							</div>
						</div>
					</div>
					{/* third container  refactored by mo*/}
					<div
						className="w-100 m-[16px] flex h-fit flex-row justify-between
			 marker:gap-4 max-sm:flex-col 
			sm:h-[100px] sm:flex-col
			md:h-[54px] md:flex-row
			lg:mr-0 lg:h-[54px]
      lg:flex-row 
	  
      
			"
					>
						<div className="flex max-sm:w-full max-sm:items-center max-sm:justify-center sm:w-full sm:justify-between lg:items-center lg:justify-between   ">
							<div className="flex items-center justify-center rtl:ml-[50%] rtl:mr-0 max-lg:mr-[20%] max-sm:mr-[0] max-sm:w-full  max-sm:rtl:ml-0 sm:w-full sm:justify-between lg:mr-[35%]">
								<img
									src={tv}
									className="h-[24px] w-[24px] max-sm:mr-5 md:rtl:ml-5 lg:rtl:m-0"
									alt="tv"
								/>
								<img
									src={conditioning}
									className="h-[24px] w-[24px] rtl:mr-3 md:ml-5 lg:ml-5 lg:rtl:m-0"
									alt="conditioning"
								/>

					{item?.classes && (

									<div
										className={`lg:ml-0] h-[40px]  w-[120px] items-center justify-center rtl:mr-5 max-sm:ml-5 max-sm:h-[40px] max-sm:w-[120px] md:ml-5 ${
											item?.classes === "Prime_Mix"
										? "bg-[#C2FBFF] text-[#00939C]"
												: item?.classes === "First10"
												? "bg-[#FFC2E3] text-[#A50059]"
												: item?.classes === "first_class"
												? "bg-[#FFC2C2] text-[#AE0000]"
												: item?.classes === "Business 40"
												? "bg-[#FFE3C2] text-[#AD5E00]"
												: item?.classes === "Economy"
												? "bg-[#E4FFC2] text-[#4C8900]"
												: item?.classes === "Comfort"
												? "bg-[#C2DEFF] text-[#004EAA]"
											: "bg-[#F9B35F] text-[#3a342d]"
										}  p-[8px] align-middle text-[14px]max-sm:rtl:mr-5`}
										style={{ borderRadius: "24px", textAlign: "center" }}
									>
										{item?.classes}
									</div>
								)}

								<div
									className="h-fit w-fit justify-center p-[8px] align-middle  text-[16px] text-[#69696A] rtl:mr-0 max-lg:ml-[0%!important] max-lg:mr-[25%] max-sm:m-auto max-sm:text-[12px] max-sm:leading-[15px] sm:ml-[30%] sm:w-[30%] md:ml-[30%] lg:mr-[30%] lg:ml-[0%!important]   "
									style={{ borderRadius: "24px", textAlign: "center" }}
								>
									{item?.available_seats !== 0 && (
										<div className="max-sm:text-[1rem] sm:text-[1rem]">
											{item?.available_seats} {t("seats free")}{" "}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="lg:rtl: flex justify-between">
							<div className="flex w-[80px] flex-col justify-between">
								<h4 className="text-[20px] font-[700] text-[#1E1E1E]">
									{item?.prices_start_with} {t("LE")}
								</h4>
								<span className="text-[10px] max-sm:text-[8px]">
									{t("Price per person")}
								</span>
							</div>
							<div className="h-full justify-center align-middle">
								<button
									className="btn-hover h-full min-h-[48px] w-[110px] text-[20px] text-white lg:w-[100px]"
									style={{ borderRadius: "9px" }}
									onClick={() => {
										navigate(item?.trip_url);
										localStorage.setItem('current_company_name', item.gateway_id);
										// console.log("company name is: " + item.comapny_name)
										console.log("company name is: " + item.gateway_id)

									}}
								>
									{t("select")}
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	return <div className="max-sm:overflow-auto max-sm:h-[70vh]">{busCardContainer(refactoredData)}</div>;
};

export default FlightCard;
