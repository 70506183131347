import { BackButtom } from "components/BackButtom/BackButtom";
import EditProfile from "components/EditProfile/EditProfile";
import ProfileCard from "components/ProfileCard/ProfileCard";
import classes from "./Profile.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "shared/FaceBook/Layout";
const Profile = () => {
	const navigate = useNavigate();
	const checkIfUserIsLoggedIn = () => {
		const accessToken = localStorage.getItem("accessToken");
		const itemToken = localStorage.getItem("token");

		const isLoggedIn =
			(accessToken !== null && accessToken.length > 10) ||
			(itemToken !== null && itemToken.length > 10);
		if (!isLoggedIn) {
			navigate("/login");
		}

		return isLoggedIn ? accessToken : itemToken;
	};

	checkIfUserIsLoggedIn();
	return (
		<Layout>
			<div className="{classes.layout}">
				<div className="container">
					<BackButtom />
					<div className="two mt-5 mb-5 min-h-[80vh] gap-[20px]">
						<ProfileCard />
						<EditProfile />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Profile;
