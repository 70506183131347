import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
function extractDataFromURL(url: string): Record<string, string> {
	const parts = url.split('/').filter(Boolean);
	const data: Record<string, string> = {};
  
	parts.forEach((part, index) => {
	  const key = `key${index + 1}`;
	  data[key] = part;
	});
  
	return data;
  }
  
export const EconomyBus = ({ setSelected, seats }: any) => {
	const { t } = useTranslation();
	const [selectedList, setSelectedList] = useState<any>([]);
	const [compnay, setCompany] = useState<string>("");

	const setContainer = (e: any) => {
		if (
			e.target.classList.contains("seat") &&
			!e.target.classList.contains("occupied")
		) {
			e.target.classList.toggle("selected");
			const seat: any = {};
			if (e.target?.id) {
				seat[`seat_${e.target?.id}`] = e.target?.id;
				if (selectedList[`seat_${e.target?.id}`] === undefined) {
					setSelectedList({ ...selectedList, ...seat });
					setSelected({ ...selectedList, ...seat });
					console.log(seat);
				} else {
					const seats = selectedList;

					delete seats[`seat_${e.target?.id}`];
					setSelectedList({ ...seats });
					setSelected({ ...seats });
				}
			}
		}
	};

	const setSelectedSeats = () => {
		// const seats = document.querySelectorAll(".row .seat:not(.occupied)");
		const list = [
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"11",
			"12",
			"13",
			"14",
			"15",
			"16",
			"17",
			"18",
			"19",
			"20",
			"21",
			"22",
			"23",
			"24",
			"25",
			"26",
			"27",
			"28",
			"29",
			"30",
			"31",
			"32",
			"33",
			"34",
			"35",
			"36",
			"37",
			"38",
			"39",
			"40",
			"41",
			"42",
			"43",
			"44",
			"45",
			"46",
			"47",
			"48",
			"49",
		];
		list.forEach(item => {
			const ele: any = document.getElementById(item);
			const filterSeats = seats.filter((seat: any) => seat?.id + "" === item);
			if (filterSeats?.length === 0) {
				ele.classList.add("occupied");
			} else {
				ele.classList.remove("occupied");
			}
		});
	};
	
	const setOccupiedSeats = () => {
		// const seats = document.querySelectorAll(".row .seat:not(.occupied)");
		const list = [
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"11",
			"12",
			"13",
			"14",
			"15",
			"16",
			"17",
			"18",
			"19",
			"20",
			"21",
			"22",
			"23",
			"24",
			"25",
			"26",
			"27",
			"28",
			"29",
			"30",
			"31",
			"32",
			"33",
			"34",
			"35",
			"36",
			"37",
			"38",
			"39",
			"40",
			"41",
			"42",
			"43",
			"44",
		];
		list.forEach(item => {
			const ele: any = document.getElementById(item);
			ele.classList.add("occupied");
		});
	};
console.log("type bus",extractDataFromURL(window.location.href).key11)

	useEffect(() => {
		if (seats?.length > 0) {
			setSelectedSeats();
			setCompany(extractDataFromURL(window.location.href).key11)

		} else {
			setOccupiedSeats();
		}
	}, [JSON.stringify(seats)]);
	const seatRowsUp = [
		{ leftSideSeats: ['1', '2'], wrightSideSeats: ['3', '4'] },
		{ leftSideSeats: ['5', '6'], wrightSideSeats: ['7', '8'] },
		{ leftSideSeats: ['9', '10'], wrightSideSeats: ['11', '12'] },
		{ leftSideSeats: ['13', '14'], wrightSideSeats: ['15', '16'] },
		{ leftSideSeats: ['17', '18'], wrightSideSeats: ['19', '20'] },
	  ];
	const seatRowsMidlle = [
		{ leftSideSeats: ['25', '26'], wrightSideSeats: ['27', '28'] },
		{ leftSideSeats: ['29', '30'], wrightSideSeats: ['31', '32'] },
		{ leftSideSeats: ['33', '34'], wrightSideSeats: ['35', '36'] },
		{ leftSideSeats: ['37', '38'], wrightSideSeats: ['39', '40'] },
		{ leftSideSeats: ['41', '42'], wrightSideSeats: ['43', '44'] },
	  ];
function generateSeatRow(company: string , leftSideSeats:string[], wrightSideSeats:string[]): JSX.Element {
		if (company === 'HighBus') {
			// here write side are started from wright
		  return (
			<div className="row">
				{
			leftSideSeats.map((seat)=>{
				return (
					<>
			  <div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={seat} id={seat}>{seat}</div>
					</>
				)
			})
				}
			  <div className="seat opacity-0"></div>
				{
			wrightSideSeats.map((seat)=>{
				return (
					<>
			  <div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={seat} id={seat}>{seat}</div>
					</>
				)
			})
				}
			</div>
		  );
		} else {
			// here write side are started from left

		  return (
			<div className="row">
				{
			leftSideSeats.map((seat)=>{
				return (
					<>
			  <div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={seat} id={seat}>{seat}</div>
					</>
				)
			})
				}
			  <div className="seat opacity-0"></div>
				{
			wrightSideSeats.map((seat)=>{
				return (
					<>
			  <div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={seat} id={seat}>{seat}</div>
					</>
				)
			})
				}
			</div>

		  );
		}
	  }
	return (
		<div className="mt-4 " dir="ltr">
			<h3 className="w-full text-2xl font-semibold">{t("selectSeats")}</h3>
			<div className="mt-2 w-full">
				<div
					className={`my-5 inline-block w-full transform overflow-hidden rounded-2xl border border-black border-opacity-5 bg-white text-left align-middle text-neutral-900 transition-all dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 sm:my-8`}
				>
					<div className="movie-container  px-1 sm:px-0">
						<ul className="showcase">
							<li>
								<div className="seat"></div>
								<small>{t("availableSeat")}</small>
							</li>
							<li>
								<div className="seat selected"></div>
								<small>{t("ownSelect")}</small>
							</li>
							<li>
								<div className="seat occupied"></div>
								<small>{t("notAvailable")}</small>
							</li>
						</ul>

						<div className="container" onClick={(e: any) => setContainer(e)}>
						<div className="row">
								<div className="flex justify-center items-end steering text-[14px] text-white font-[600] " ></div>
								
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>

							</div>
							{
					seatRowsUp.map((row)=>{
						return (
							<>
							{generateSeatRow(compnay, row.leftSideSeats, row.wrightSideSeats)}

							</>
						)
					})}
								

							<div className="row">
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"21"} id={"21"}>21</div>
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"22"} id={"22"}>22</div>
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>
							</div>
							<div className="row">
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"23"} id={"23"}>23</div>
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"24"} id={"24"}>24</div>
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>
								<div className="seat opacity-0"></div>
							</div>
							{
					seatRowsMidlle.map((row)=>{
						return (
							<>
							{generateSeatRow(compnay, row.leftSideSeats, row.wrightSideSeats)}

							</>
						)
					})}
							
							
							<div className="row">
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"45"} id={"45"}>45</div>
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600] " title={"46"} id={"46"}>46</div>
								
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={"47"} id={"47"}>47</div>
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={"48"} id={"48"}>48</div>
								<div className="flex justify-center items-end seat text-[14px] text-white font-[600]" title={"49"} id={"49"}>49</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
